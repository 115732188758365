import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { OfferItemRowNumber } from '@/interfaces/components/ProductsInOffer/OfferItemRowNumber';
import { ProductTypes } from '@/enums/global/ProductTypes';
import { MultipositionProduct } from '@/interfaces/components/projectNew/MultipositionProduct';

export default class OfferItemAPI {
    public static async createNew(
        activeProductFormValues: ActiveProductFormValueObject,
        offerId: string,
        hasChecklistErrors: boolean,
        connection: null | number = null,
        production = false,
        errorsHaveValidWarranty: boolean | null,
        productFormId: number | null
    ) {
        let offerItem;
        const request: any = {
            data: {
                type: 'offer_items',
                productFormId: productFormId,
                attributes: {
                    offerItemType: ProductTypes.PRODUCT,
                    formData: activeProductFormValues,
                    hasErrors: hasChecklistErrors,
                    connection,
                    production,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerId,
                        },
                    },
                },
            },
        };

        if (errorsHaveValidWarranty !== null) {
            request.data.attributes.validWarranty = errorsHaveValidWarranty;
        }

        try {
            offerItem = (await ApiController.post(ApiRoutes.offerItem.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async createNewBulk(payload: MultipositionProduct[], offerId: string) {
        let offerItem;

        const request = payload;

        try {
            offerItem = (await ApiController.post(ApiRoutes.offerItem.createBulk(offerId), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async updateNewBulk(payload: MultipositionProduct[], offerId: string) {
        let offerItem;

        const request = payload;

        try {
            offerItem = (await ApiController.patch(ApiRoutes.offerItem.updateBulk(offerId), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async delete(offerItemId: string) {
        try {
            await ApiController.delete(ApiRoutes.offerItem.delete(offerItemId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getById(offerItemId: string) {
        let offerItem;
        try {
            offerItem = (await ApiController.get(ApiRoutes.offerItem.getSingle(offerItemId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async updateExisting(
        offerItemId: string,
        formData: ChecklistFieldEntry[],
        offerItemType: string,
        connection: null | number,
        production: boolean,
        hasChecklistErrors: boolean,
        allowErrors: boolean | null,
        errorsHaveValidWarranty: boolean | null,
        productFormId: number | null
    ) {
        const request: any = {
            data: {
                type: 'offer_items',
                id: offerItemId,
                productFormId: productFormId,
                attributes: {
                    offerItemType,
                    formData,
                    hasErrors: hasChecklistErrors,
                    connection,
                    production,
                },
            },
        };

        if (allowErrors !== null) {
            request.data.attributes.allowErrors = allowErrors;
        }

        if (errorsHaveValidWarranty !== null) {
            request.data.attributes.validWarranty = errorsHaveValidWarranty;
        }

        let offerItem;
        try {
            offerItem = (await ApiController.patch(
                ApiRoutes.offerItem.updateExisting(offerItemId),
                request
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async updateAllowErrorState(id: string, state: boolean, productFormId: number) {
        const request = {
            data: {
                type: 'offer_items',
                id: id,
                productFormId: productFormId,
                attributes: {
                    offerItemType: 'product',
                    allowErrors: state,
                },
            },
        };

        let offerItem;

        try {
            offerItem = (await ApiController.patch(ApiRoutes.offerItem.updateExisting(id), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async updateAllowWarrantyState(id: string, state: boolean, productFormId: number) {
        const request = {
            data: {
                type: 'offer_items',
                id: id,
                productFormId: productFormId,
                attributes: {
                    offerItemType: 'product',
                    validWarranty: state,
                },
            },
        };

        let offerItem;

        try {
            offerItem = (await ApiController.patch(ApiRoutes.offerItem.updateExisting(id), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async duplicateExisting(offerItemId: string) {
        let offerItem;
        try {
            offerItem = (await ApiController.post(
                ApiRoutes.offerItem.duplicateExisting(offerItemId),
                {}
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async createMaterial(
        activeProductFormValues: ActiveProductFormValueObject,
        productCatalogueId: string,
        offerId: string,
        connection: number | null,
        production: boolean
    ) {
        const request = {
            data: {
                type: 'offer_items',
                productFormId: null,
                attributes: {
                    offerItemType: ProductTypes.MATERIAL,
                    formData: activeProductFormValues,
                    connection,
                    production,
                    hasErrors: false,
                },
                relationships: {
                    material: {
                        data: {
                            type: 'product_catalogs',
                            id: productCatalogueId,
                        },
                    },
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerId,
                        },
                    },
                },
            },
        };

        let offerItem;
        try {
            offerItem = (await ApiController.post(ApiRoutes.offerItem.createMaterial, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async updateExistingMaterial(offerItemId: string, formData: ChecklistFieldEntry[]) {
        const request = {
            data: {
                type: 'offer_items',
                id: offerItemId,
                attributes: {
                    offerItemType: ProductTypes.MATERIAL,
                    formData,
                },
            },
        };

        let offerItem;
        try {
            offerItem = (await ApiController.patch(
                ApiRoutes.offerItem.updateExisting(offerItemId),
                request
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }

    public static async updateRowNumbers(rowNumbers: OfferItemRowNumber[]) {
        const request = {
            data: {
                attributes: rowNumbers,
            },
        };

        let offerItem;
        try {
            offerItem = (await ApiController.patch(ApiRoutes.offerItem.updateRowNumbers(), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItem);
    }
}
