export enum UserRightsEnum {
    CREATE_CLIENT = 'CREATE_CLIENT',
    SEE_INQUIRIES = 'SEE_INQUIRIES',
    VIEW_DISCOUNTS = 'VIEW_DISCOUNTS',
    EDIT_DISCOUNTS = 'EDIT_DISCOUNTS',
    VIEW_PRICETYPE_AND_PRICELIST = 'VIEW_PRICETYPE_AND_PRICELIST',
    EDIT_PRICETYPE_AND_PRICELIST = 'EDIT_PRICETYPE_AND_PRICELIST',
    VIEW_RECOMMENDED_PRICE = 'VIEW_RECOMMENDED_PRICE',
    EDIT_CLIENT = 'EDIT_CLIENT',
    DELETE_CLIENT = 'DELETE_CLIENT',
    VIEW_USER = 'VIEW_USER',
    CREATE_USER = 'CREATE_USER',
    EDIT_USER = 'EDIT_USER',
    EDIT_CLIENT_DETAILED = 'EDIT_CLIENT_DETAILED',
    EDIT_USER_RIGHTS = 'EDIT_USER_RIGHTS',
    CMS_TRANSLATIONS = 'CMS_TRANSLATIONS',
    CMS_CONTENT = 'CMS_CONTENT',
    CMS_INFO_BUTTONS = 'CMS_INFO_BUTTONS',
    CMS_STATUSES = 'CMS_STATUSES',
    CMS_CAMPAIGNS = 'CMS_CAMPAIGNS',
    CMS_CAMPAIGN_TYPES = 'CMS_CAMPAIGN_TYPES',
    CMS_NEXT_STEP_TYPES = 'CMS_NEXT_STEP_TYPES',
    CMS_CLIENT_TYPES = 'CMS_CLIENT_TYPES',
    CMS_CATALOG = 'CMS_CATALOG',
    CMS_POST_CODE_CONNECTIONS = 'CMS_POST_CODE_CONNECTIONS',
    CMS_DOCUMENT_NAMES = 'CMS_DOCUMENT_NAMES',
    CMS_OFFER_CONFIGURATIONS = 'CMS_OFFER_CONFIGURATIONS',
    CMS_TAXES = 'CMS_TAXES',
    CMS_LEAD_TYPES = 'CMS_LEAD_TYPES',
    CMS_ORDER_STATUSES = 'CMS_ORDER_STATUSES',
    CMS_LEAD_ORIGINS = 'CMS_LEAD_ORIGINS',
    CMS_CURRENCIES = 'CMS_CURRENCIES',
    CMS_EMAIL_TEMPLATE_PROPERTIES = 'CMS_EMAIL_TEMPLATE_PROPERTIES',
    VIEW_LEAD_ASSIGN = 'VIEW_LEAD_ASSIGN',
    DELETE_PROJECTS = 'DELETE_PROJECTS',
    VIEW_PURCHASE_PRICE_MANIPULATIONS = 'VIEW_PURCHASE_PRICE_MANIPULATIONS',
    EDIT_PURCHASE_PRICE_MANIPULATIONS = 'EDIT_PURCHASE_PRICE_MANIPULATIONS',
    VIEW_PURCHASE_PRICE = 'VIEW_PURCHASE_PRICE',
    VIEW_PAYMENT_RECORDS = 'VIEW_PAYMENT_RECORDS',
    EDIT_PAYMENT_RECORDS = 'EDIT_PAYMENT_RECORDS',
    SEND_ORDER = 'SEND_ORDER',
    ALLOW_OFFER_ERRORS = 'ALLOW_OFFER_ERRORS',
    B2B_CONTROL_PANEL = 'B2B_CONTROL_PANEL',
    VIEW_ALL_NEXT_STEPS = 'VIEW_ALL_NEXT_STEPS',
    EDIT_NEXT_STEPS = 'EDIT_NEXT_STEPS',
    DELETE_NEXT_STEPS = 'DELETE_NEXT_STEPS',
    VIEW_CHAT = 'VIEW_CHAT',
    CMS_AUTOMATIC_PRICE_MANIPULATIONS = 'CMS_AUTOMATIC_PRICE_MANIPULATIONS',
    CMS_PRODUCT_GROUPS = 'CMS_PRODUCT_GROUPS',
    DELETE_OFFER = 'DELETE_OFFER',
    CMS_ORDER_PDF_TEMPLATE = 'CMS_ORDER_PDF_TEMPLATE',
    AGENT_USER_RIGHT = 'AGENT_USER_RIGHT',
    CMS_FLAGGED_FLAGGED_PDF_VALUES = 'CMS_FLAGGED_FLAGGED_PDF_VALUES',
    PRINT_OFFER_SPECIFICATION = 'PRINT_OFFER_SPECIFICATION',
    CMS_VALID_WARRANTY = 'CMS_VALID_WARRANTY',
    VIEW_ROLTEK_MONTAGE = 'VIEW_ROLTEK_MONTAGE',
    VIEW_PRODUCT_VERSIONS = 'VIEW_PRODUCT_VERSIONS',
    VIEW_CALCULATOR = 'VIEW_CALCULATOR',
}
