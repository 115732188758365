
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChecklistIntegerField } from '@/interfaces/components/configurator/ChecklistIntegerField';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { validateAndFormatInteger } from '@/helpers/NumberFormatter';
import { Getter } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'ChecklistIntegerFieldModule',
})
export default class ChecklistIntegerFieldModule extends Vue {
    @Prop({ required: true }) public checklistIntegerField!: ChecklistIntegerField;
    @Prop({ required: true }) private pId!: string;
    @Prop({ default: null }) private debounceToggleIsActive!: ((state: boolean) => void) | null;
    @Prop() private activeProductFormId!: number | null;
    @Getter('configurator/activeProductFormValue') private productFormValue!: (
        pId: string,
        productFormId: number
    ) => number | null;

    private value: number = this.checklistIntegerField.defaultValue;

    private get fieldValue() {
        if (this.activeProductFormId == null) {
            return this.checklistIntegerField.min;
        }

        return this.productFormValue(this.pId, this.activeProductFormId);
    }

    private onBlur(a: any) {
        if (this.value === null) {
            this.onChange(1);
        }

        if (this.value !== this.fieldValue || this.activeProductFormId == null) {
            this.onChange(this.value);
        }

        this.debounceToggleIsActive && this.debounceToggleIsActive(false);
    }

    private onFocus() {
        window.setTimeout(() => {
            this.debounceToggleIsActive && this.debounceToggleIsActive(true);
        }, 200);
    }

    private onChange(newValue: number | null) {
        EventBus.$emit(EventBusEvents.changesInDataMade, { state: true });

        this.$emit(EventBusEvents.updateStoreFieldValue, {
            pId: this.pId,
            value: validateAndFormatInteger(newValue, this.checklistIntegerField.min, this.checklistIntegerField.max),
            productFormId: this.activeProductFormId,
        });
    }

    @Watch(`fieldValue`, { immediate: true })
    private onFieldValueChange(newValue: number | null) {
        this.value = validateAndFormatInteger(newValue, this.checklistIntegerField.min, this.checklistIntegerField.max);
    }

    private formatNumber(value: number | string | null): number {
        return validateAndFormatInteger(value, this.checklistIntegerField.min, this.checklistIntegerField.max);
    }
}
