import { ColumnOptions } from '@/interfaces/ColumnOptions';
import { Column } from '@/interfaces/Column';
import moment from 'moment';
import router from '@/router/index';
import { RouteNames } from '@/enums/routes/RouteNames';
import { AppTableColumn } from '@/enums/components/AppTableColumn';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { ColumnSortedInfo } from '@/interfaces/ColumnSortedInfo';
import { LocalStorageService } from '@/services/LocalStorageService';
import i18n from '@/i18n';
import { sortDates, sortStrings } from '@/helpers/SortingHelper';
import { constructUrlParams } from '@/helpers/SearchHelper';
import NextStep from '@/models/NextStep';
import Project from '@/models/Project';
import { FetchTableEntitiesConfiguration } from '@/interfaces/components/ProjectTable/FetchTableEntitiesConfiguration';
import { PrintTableConfigurator } from '@/interfaces/components/ProjectTable/PrintTableConfigurator';
import { FilterUrl } from '@/interfaces/general/FilterUrl';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { TablesArrayConfiguration } from '@/interfaces/general/TablesArrayConfiguration';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import Offer from '@/models/Offer';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { notification } from 'ant-design-vue';
import Vue from 'vue';
import { generateStateSpecificId, extractIdOfNewestOffer } from '@/helpers/project/ProjectHelper';
import { OfferStates } from '@/enums/global/OfferStates';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { getSelectedOfferPrice } from '@/helpers/NewProject/NewProjectHelper';
import { formatCurrency } from '@/helpers/NumberFormatter';
import { phoneIcon } from '@/icons/PhoneIcon';
import { enviromentIcon } from '@/icons/EnviromentIcon';
import ProjectSearch from '@/models/ProjectSearch';

export function createColumnData<T extends { [key: string]: any }>(
    columnOptions: ColumnOptions[],
    sortedInfo: ColumnSortedInfo | null,
    currentEntityType: string,
    isCurrentUserGroupRoltek: boolean | null,
    callback?: (record?: any) => Promise<void>,
    navigation: boolean = true,
    userGroup: string | null = null,
    isCurrentUserTechnologist: boolean = false
): Array<Column<T>> {
    const columnData: Array<Column<T>> = [];
    const datePickerLocale = getDatepickerLocale();

    columnOptions.forEach((columnOption: ColumnOptions) => {
        if (currentEntityType === ProjectTypes.Request) {
            columnData.push(
                formatTableValuesForRequestTab(
                    columnOption,
                    sortedInfo,
                    currentEntityType,
                    datePickerLocale,
                    navigation,
                    userGroup,
                    callback
                )
            );
        } else {
            columnData.push(
                formatTableValuesForAllTabs(
                    columnOption,
                    sortedInfo,
                    currentEntityType,
                    datePickerLocale,
                    navigation,
                    userGroup,
                    callback,
                    isCurrentUserTechnologist
                )
            );
        }
    });

    return columnData;
}

function formatTableValuesForRequestTab<T extends { [key: string]: any }>(
    columnOption: ColumnOptions,
    sortedInfo: ColumnSortedInfo | null,
    currentEntityType: string,
    datePickerLocale: any,
    navigation: boolean = true,
    userGroup: string | null,
    callback?: (record?: any) => Promise<void>
): Column<T> {
    const strippedKey = columnOption.shouldStripNumbers ? columnOption.key.replace(/\d+/, '') : columnOption.key;
    const columns: Column<T> = {
        title: i18n.t(columnOption.name) as string,
        dataIndex: columnOption.key,
        key: columnOption.key,
        sortOrder: sortedInfo && sortedInfo.columnKey === columnOption.key ? sortedInfo.order : null,
        customCell(record: T): object {
            let renderText: null | string = record[strippedKey];
            let color: string | null = '';
            if (strippedKey === 'status') {
                const projectHasActiveOrderStatus = record.orderStatus != null;
                if (projectHasActiveOrderStatus) {
                    renderText = record.orderStatus.name;
                } else {
                    renderText = record.label ? record.label.name : '-';
                }

                if (record.state === 'request') {
                    renderText = i18n.t('Roltek kontrola') as string;
                }

                if (projectHasActiveOrderStatus) {
                    color = null;
                }

                if (record.label) {
                    color = record.label.color;
                }
            }

            if (strippedKey === 'orderNumber') {
                const orderedOffer = record.offerRevisions.filter(
                    (offer: Offer) => offer.state === OfferStates.ORDERED
                );

                renderText = record.orderNumber != null ? record.orderNumber : orderedOffer[0]?.name ?? '';
            }

            if (strippedKey === 'typeSpecificId') {
                renderText = generateStateSpecificId(record as unknown as Project);
            }
            if (strippedKey === 'offerRevisions.name') {
                renderText = extractIdOfNewestOffer(record as unknown as Project);
            }

            if (strippedKey === 'dateCreated') {
                switch (record.state) {
                    case 'offer':
                        const sortedOffers = sortArrayByGivenPropertyName(
                            record.offerRevisions.slice(),
                            columnOption.sortBy
                        );
                        renderText = sortedOffers.splice(-1)[0].createdAt;
                        break;
                    case 'order':
                        const orderedOffer = record.offerRevisions.filter(
                            (offer: Offer) => offer.state === OfferStates.ORDERED
                        );
                        const importedOffer = record.offerRevisions.filter(
                            (offer: Offer) => offer.state === OfferStates.IMPORTED
                        );

                        const offerToBeUsed = orderedOffer.length > 0 ? orderedOffer : importedOffer;

                        renderText = offerToBeUsed.length > 0 ? offerToBeUsed[0].createdAt : null;
                        break;
                    default:
                        renderText = record.sortDate;
                }
            }

            if (strippedKey === 'manufactureDate') {
                if (record.manufactureDate && record.manufactureDate !== 'null') {
                    renderText = record.manufactureDate;
                } else {
                    renderText = '';
                }
            }

            if (strippedKey === 'sellingOfferPrice.priceWithTax') {
                let priceValue = getSelectedOfferPrice(record?.id);
                if (!priceValue || priceValue === '-') {
                    priceValue = record?.offerRevisions[0]?.sellingOfferPrice?.priceWithTax;
                }
                if (priceValue !== undefined) {
                    renderText = formatCurrency(priceValue as any as number, 2, 2);
                } else {
                    renderText = '-';
                }
            }

            if (columnOption.type.includes('nested')) {
                const keys = strippedKey.split('.');
                let value = record;
                for (const key of keys) {
                    if (value == null) {
                        renderText = null;
                        break;
                    }
                    value = value[key];
                }
                renderText = value !== null ? (value as unknown as string) : '';
                if (columnOption.type.includes('contact')) {
                    // add additional personal contact information
                    renderText = `<span style="color: black;">` + renderText + `</span>`;
                    renderText += '<br/><span class="client-information">';
                    let phoneValue: string = '';
                    switch (strippedKey) {
                        case 'name':
                            phoneValue = record?.contactPersons[0]?.phone;
                            break;
                        case 'project.client.name':
                            phoneValue = record?.project?.contactPerson?.phone;
                            break;
                        default:
                            phoneValue = record?.contactPerson?.phone ?? record?.client?.contactPersons[0]?.phone;
                            break;
                    }
                    if (phoneValue && phoneValue !== '') {
                        renderText +=
                            `<a href=tel:"${phoneValue}"><span>${phoneIcon}</span> ` +
                            (phoneValue as unknown as string) +
                            `</a>`;
                    }
                    let addressStreetValue = '';
                    let addressPostValue = '';
                    let addressCityValue = '';
                    switch (strippedKey) {
                        case 'project.client.name':
                            addressStreetValue = record?.project?.client?.address?.streetNameAndNumber;
                            addressPostValue = record?.project?.client?.address?.poNumber;
                            addressCityValue = record?.project?.client?.address?.city;
                            break;
                        default:
                            addressStreetValue = record?.client?.address?.streetNameAndNumber;
                            addressPostValue = record?.client?.address?.poNumber;
                            addressCityValue = record?.client?.address?.city;
                            break;
                    }
                    const isAndroid = /Android/i.test(navigator.userAgent);
                    if (isAndroid) {
                        const geoLoaction = encodeURIComponent(
                            'geo:0,0?q=' + addressStreetValue + '+' + addressCityValue
                        );
                        renderText += `<a href="${geoLoaction}">`;
                    }
                    if (addressStreetValue && addressStreetValue !== null && addressStreetValue !== '') {
                        renderText += `<span>${enviromentIcon}</span>` + ' ' + addressStreetValue + ',';
                    }

                    if (addressPostValue && addressPostValue !== null) {
                        renderText += ' ' + addressPostValue;
                    }

                    if (addressCityValue && addressCityValue !== null) {
                        renderText += ' ' + addressCityValue;
                    }
                    renderText += isAndroid ? `</a>` : '';
                    renderText += '</span>';
                }

                if (columnOption.badge) {
                    keys.splice(-1, 1, 'color');
                    let colorValue = record;
                    for (const key of keys) {
                        if (colorValue == null) {
                            color = null;
                            break;
                        }
                        colorValue = colorValue[key];
                    }
                    color = colorValue as unknown as string;
                }
            }
            if (columnOption.type.includes('datetime')) {
                renderText =
                    (renderText ? moment(renderText).format(datePickerLocale.lang.dateFormat) : '-') +
                    ' ' +
                    (renderText ? moment(renderText).format('HH:mm:ss') : '-');
            } else if (columnOption.type.includes('date')) {
                renderText = renderText ? moment(renderText).format(datePickerLocale.lang.dateFormat) : '-';
            } else if (columnOption.type.includes('time')) {
                renderText = renderText ? moment(renderText).format('HH:mm:ss') : '-';
            }
            if (columnOption.type.includes('array')) {
                const keys = strippedKey.split('.');
                const lastKey = keys.splice(-1)[0];
                let values = record;

                for (const key of keys) {
                    if (values == null) {
                        renderText = null;
                        break;
                    }
                    values = values[key];
                }

                values = filterArrayIfNecessary(values as unknown as any[], columnOption.key) as unknown as T;

                if (values == null || values.length <= 0) {
                    renderText = null;
                } else {
                    renderText = handleArraysDisplayValue({
                        array: (values as unknown as any[]).slice(),
                        columnOption,
                        propertyToBeDisplayed: lastKey,
                        dateFormat: datePickerLocale.lang.dateFormat,
                        projectState: record.state,
                    });
                }
            }
            if (renderText == null) {
                renderText = '-';
            }

            let badgeText = renderText;

            if (record.orderStatus != null) {
                badgeText = record.orderStatus.name;
            }
            if (record.state === ProjectStates.REQUEST) {
                badgeText = i18n.t('Roltek kontrola') as string;
            }

            return {
                class: `${columnOption.badge ? 'has-badge' : ''} ${columnOption.isNarrowWidth ? 'is-narrow' : ''} ${
                    columnOption.shouldBreakNone ? 'break-none' : ''
                } ${columnOption.ellipsis ? 'has-ellipsis' : ''} ${columnOption.shouldBreakAll ? 'break-all' : ''}`,
                on: {
                    click: async () => {
                        if (currentEntityType !== 'clients') {
                            LocalStorageService.save('lastSelectedTab', currentEntityType);
                            const initialTab = determineInitialTab(record.state);

                            if (callback != null) {
                                await callback(record);
                            }
                            if (navigation) {
                                router.push({
                                    name: RouteNames.project,
                                    params: {
                                        id: record.id,
                                        tab: 'request',
                                    },
                                    query: {
                                        initialTab: initialTab,
                                        ug: userGroup,
                                    },
                                });
                            }
                        } else if (navigation) {
                            router.push({
                                name: RouteNames.editClient,
                                params: {
                                    clientId: record.id,
                                },
                                query: { initialTab: String(ClientTabs.CUSTOMER) },
                            });
                        }
                    },
                },
                domProps: {
                    innerHTML: columnOption.badge
                        ? `<button class="u-b3 status-switcher ant-btn ant-dropdown-trigger"
                        style="background-color:${record.state === 'request' ? null : `${color}20`};
                            white-space: nowrap;
                            border-color:${record.state === 'request' ? null : color};
                            color:${record.state === 'request' ? null : color}">${badgeText}
                        </button>`
                        : `<span>${renderText}</span>`,
                },
            };
        },
    };
    if (columnOption.shouldSort) {
        columns.sorter = true;
    }
    if (columnOption.shouldFilter) {
        columns.scopedSlots = {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        };
    }

    if (columnOption.type.includes('editable')) {
        columns.customCell = undefined;
        columns.scopedSlots = {
            customRender: columnOption.slotName,
        };
    }

    return columns;
}

function determineInitialTab(tab: string) {
    switch (tab) {
        case ProjectStates.LEAD:
            return '0';
        case ProjectStates.OFFER:
            return '2';
        case ProjectStates.ORDER:
            return '3';
        default:
            return '2';
    }
}

function formatTableValuesForAllTabs<T extends { [key: string]: any }>(
    columnOption: ColumnOptions,
    sortedInfo: ColumnSortedInfo | null,
    currentEntityType: string,
    datePickerLocale: any,
    navigation: boolean = true,
    userGroup: string | null,
    callback?: (record?: any) => Promise<void>,
    isCurrentUserTechnologist: boolean = false
): Column<T> {
    const strippedKey = columnOption.shouldStripNumbers ? columnOption.key.replace(/\d+/, '') : columnOption.key;
    const columns: Column<T> = {
        title: i18n.t(columnOption.name) as string,
        dataIndex: columnOption.key,
        key: columnOption.key,
        sortOrder: sortedInfo && sortedInfo.columnKey === columnOption.key ? sortedInfo.order : null,
        customCell(record: T): object {
            let renderText: null | string = record[strippedKey];
            let color: string | null = '';

            if (strippedKey === 'typeSpecificId') {
                renderText = record?.typeSpecificId || record?.id || '-';
            }

            if (strippedKey === 'orderNumber') {
                renderText = record?.orderNumber || record?.id || '-';
            }

            if (isCurrentUserTechnologist && strippedKey === 'price') {
            }

            if (strippedKey === 'price') {
                if (record.price && !isCurrentUserTechnologist) {
                    renderText = formatCurrency(record.price as any as number, 2, 2);
                } else {
                    renderText = '-';
                }
            }

            if (strippedKey === 'connection') {
                if (record?.connection) {
                    renderText = record.connection;
                } else {
                    renderText = '-';
                }
            }

            if (columnOption.type.includes('nested')) {
                const keys = strippedKey.split('.');
                let value = record;
                for (const key of keys) {
                    if (value == null) {
                        renderText = null;
                        break;
                    }
                    value = value[key];
                }
                renderText = value !== null ? (value as unknown as string) : '';
                if (columnOption.type.includes('contact')) {
                    // add additional personal contact information
                    renderText = `<span style="color: black;">` + renderText + `</span>`;
                    renderText += '<br/><span class="client-information">';
                    let phoneValue: string = record?.contactPersonPhone;

                    if (phoneValue) {
                        renderText +=
                            `<a href=tel:"${phoneValue}"><span>${phoneIcon}</span> ` +
                            (phoneValue as unknown as string) +
                            `</a>`;
                    }

                    let addressStreetValue = record?.clientStreetNameAndNumber;
                    let addressPostValue = record?.clientPoNumber ?? '';
                    let addressCityValue = record?.clientCity ?? '';

                    const isAndroid = /Android/i.test(navigator.userAgent);
                    if (isAndroid) {
                        const geoLoaction = encodeURIComponent(
                            'geo:0,0?q=' + addressStreetValue + '+' + addressCityValue
                        );
                        renderText += `<a href="${geoLoaction}">`;
                    }
                    if (addressStreetValue && addressStreetValue !== null && addressStreetValue !== '') {
                        renderText += `<span>${enviromentIcon}</span>` + ' ' + addressStreetValue + ',';
                    }

                    if (addressPostValue && addressPostValue !== null) {
                        renderText += ' ' + addressPostValue;
                    }

                    if (addressCityValue && addressCityValue !== null) {
                        renderText += ' ' + addressCityValue;
                    }
                    renderText += isAndroid ? `</a>` : '';
                    renderText += '</span>';
                }

                if (columnOption.badge) {
                    keys.splice(-1, 1, 'color');
                    let colorValue = record;
                    for (const key of keys) {
                        if (colorValue == null) {
                            color = null;
                            break;
                        }
                        colorValue = colorValue[key];
                    }
                    color = colorValue as unknown as string;
                }
            }

            if (columnOption.type.includes('datetime')) {
                renderText =
                    (renderText ? moment(renderText).format(datePickerLocale.lang.dateFormat) : '-') +
                    ' ' +
                    (renderText ? moment(renderText).format('HH:mm:ss') : '-');
            } else if (columnOption.type.includes('date')) {
                renderText = renderText ? moment(renderText).format(datePickerLocale.lang.dateFormat) : '-';
            } else if (columnOption.type.includes('time')) {
                renderText = renderText ? moment(renderText).format('HH:mm:ss') : '-';
            }
            if (columnOption.type.includes('array')) {
                const keys = strippedKey.split('.');
                const lastKey = keys.splice(-1)[0];
                let values = record;

                for (const key of keys) {
                    if (values == null) {
                        renderText = null;
                        break;
                    }
                    values = values[key];
                }

                values = filterArrayIfNecessary(values as unknown as any[], columnOption.key) as unknown as T;

                if (values == null || values.length <= 0) {
                    renderText = null;
                } else {
                    renderText = handleArraysDisplayValue({
                        array: (values as unknown as any[]).slice(),
                        columnOption,
                        propertyToBeDisplayed: lastKey,
                        dateFormat: datePickerLocale.lang.dateFormat,
                        projectState: record.state,
                    });
                }
            }
            if (renderText == null) {
                renderText = '-';
            }

            return {
                class: `${columnOption.isNarrowWidth ? 'is-narrow' : ''} ${
                    columnOption.shouldBreakNone ? 'break-none' : ''
                } ${columnOption.ellipsis ? 'has-ellipsis' : ''} ${columnOption.shouldBreakAll ? 'break-all' : ''}`,
                on: {
                    click: async () => {
                        if (currentEntityType !== 'clients') {
                            LocalStorageService.save('lastSelectedTab', currentEntityType);
                            const initialTab = determineInitialTab(record.state);

                            if (callback != null) {
                                await callback(record);
                            }
                            if (navigation) {
                                router.push({
                                    name: RouteNames.project,
                                    params: {
                                        id: record.id,
                                    },
                                    query: {
                                        initialTab: initialTab,
                                        ug: userGroup,
                                    },
                                });
                            }
                        } else if (navigation) {
                            router.push({
                                name: RouteNames.editClient,
                                params: {
                                    clientId: record.id,
                                },
                                query: { initialTab: String(ClientTabs.CUSTOMER) },
                            });
                        }
                    },
                },
                domProps: {
                    innerHTML: `<span>${renderText}</span>`,
                },
            };
        },
    };
    if (columnOption.shouldSort) {
        columns.sorter = true;
    }
    if (columnOption.shouldFilter) {
        columns.scopedSlots = {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        };
    }

    if (columnOption.type.includes('editable')) {
        columns.customCell = undefined;
        columns.scopedSlots = {
            customRender: columnOption.slotName,
        };
    }

    return columns;
}

export function sortArrayByGivenPropertyName(array: any[], propertyName: string | undefined) {
    let sortedArray = array;
    switch (propertyName) {
        case 'updatedAt':
        case 'createdAt':
        case 'dateFrom':
        case 'dateTo':
        case 'date':
            sortedArray = array.sort((valueA: any, valueB: any) => {
                if (propertyName == null) {
                    return -1;
                }

                return sortDates(valueA[propertyName], valueB[propertyName], true);
            });
            break;
        default:
            sortedArray = array.sort((valueA: any, valueB: any) => {
                if (propertyName == null) {
                    return -1;
                }

                return sortStrings(valueA[propertyName], valueB[propertyName], true);
            });
    }
    return sortedArray;
}

function handleArraysDisplayValue({
    array,
    columnOption,
    propertyToBeDisplayed,
    dateFormat,
    projectState,
}: TablesArrayConfiguration) {
    let valueToBeDisplayed: string | null;

    if (typeContainsPositioningKeyword(columnOption.type)) {
        const sortedArray = sortArrayByGivenPropertyName(array, columnOption.sortBy);

        valueToBeDisplayed = getCorrectElementFromArray(sortedArray, columnOption.type, propertyToBeDisplayed);
        valueToBeDisplayed = convertValueToDateIfNecessary(valueToBeDisplayed, columnOption.type, dateFormat);
    } else if (shouldDisplayValueBeLength(columnOption.type)) {
        valueToBeDisplayed = String(array.length);
    } else if (propertyToBeDisplayed === 'createdAt' && (projectState === 'order' || projectState === 'ordered')) {
        valueToBeDisplayed = extractCreatedAtDateFromOrderedOffer(
            array,
            columnOption.type,
            dateFormat,
            propertyToBeDisplayed
        );
    } else {
        const arrayOfPropertiesToBeDisplayed = array.map(
            (valueObject: any[]) => valueObject[propertyToBeDisplayed as any]
        );

        valueToBeDisplayed = arrayOfPropertiesToBeDisplayed.join(', ');
    }

    return valueToBeDisplayed;
}

function shouldDisplayValueBeLength(columnOptionType: string) {
    return columnOptionType.includes('length');
}

function convertValueToDateIfNecessary(value: string | null, columnOptionType: string, dateFormat = 'DD.MM.YYYY') {
    if (!columnOptionType.includes('date') || value == null) {
        return value;
    }

    return moment(value).format(dateFormat);
}

function getCorrectElementFromArray(array: any[], columnOptionType: string, propertyNameToGet: string) {
    return columnOptionType.includes('last') ? array.splice(-1)[0][propertyNameToGet] : array[0][propertyNameToGet];
}

function filterArrayIfNecessary(array: any[], columnOptionKey: string) {
    if (!columnOptionKey.includes('nextSteps') || array == null) {
        return array;
    }

    return array.filter((value: NextStep) => !value.completed);
}

function typeContainsPositioningKeyword(columnOptionType: string) {
    return columnOptionType.includes('last') || columnOptionType.includes('first');
}

export function constructSearchParametersFromLocalStorage(entityType: string, currentUserId: string) {
    let localStorageParameters = JSON.parse(
        LocalStorageService.get(
            // @ts-ignore
            `${entityType}-${currentUserId}`
        ) as string
    );
    if (localStorageParameters == null) {
        throw new Error('Local storage parameters do not exist');
    }
    const currentPageNumber = localStorageParameters.page;

    localStorageParameters = updatePageAndResultsNumberOnSearchParameters(localStorageParameters);

    return { query: constructUrlParams(localStorageParameters), localStorageParameters, currentPageNumber };
}

function updatePageAndResultsNumberOnSearchParameters(searchParameters: FilterUrl) {
    searchParameters.page = 1;
    searchParameters.results = 1000;

    return searchParameters;
}

export async function fetchActivitiesOrNextStepsBasedOnParameters({
    parameters,
    query,
}: FetchTableEntitiesConfiguration) {
    try {
        if (parameters.entityType === ProjectTypes.Request) {
            await Project.getFilteredProjects(query, parameters.entityType);
        } else {
            await ProjectSearch.getAll(query);
        }
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

export async function prepareAndPrintTable(
    entityType: string,
    currentUserId: string,
    printFunction: any,
    fromFilter?: string
) {
    let query!: string;
    let localStorageParameters!: FilterUrl;
    let currentPageNumber!: number;

    try {
        const searchParameters = constructSearchParametersFromLocalStorage(entityType, currentUserId);
        query = searchParameters.query;

        if (fromFilter) {
            query += '&includeAdditional=' + fromFilter;
        }

        localStorageParameters = searchParameters.localStorageParameters;
        currentPageNumber = searchParameters.currentPageNumber;

        await fetchActivitiesOrNextStepsBasedOnParameters({
            parameters: localStorageParameters,
            query,
        });
    } catch (e) {
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: (e as Error).message,
        });
        return Promise.reject();
    }

    EventBus.$emit(EventBusEvents.emitProjectPagination, {
        currentPage: localStorageParameters.page,
        pageSize: localStorageParameters.results,
    });

    await Vue.nextTick();
    // popups in the browser must be allowed in some cases where the table has a lot of data
    // for this to work
    printTable({
        // @ts-ignore
        printFunction,
        elementId: 'table',
        entityType,
        onPrintFinish: () => {
            EventBus.$emit(EventBusEvents.runEntitySearch, {
                page: currentPageNumber,
            });
            return Promise.resolve();
        },
    });
}

// Fetch few latest activities projects
export async function fetchActivitiesProjects(currentUserId: string) {
    const searchParameters = `&q=project.temporary:0^(assignedUser.id:${currentUserId})&sort=-dateFrom&page[number]=1&page[size]=3`;

    try {
        await NextStep.getFilteredProjects(searchParameters);
    } catch (e) {
        notification.error({
            message: i18n.t('Error happened') as string,
            description: (e as Error).message,
        });
        return Promise.reject();
    }

    await Vue.nextTick();

    return Promise.resolve();
}

export function printTable({ printFunction, elementId, entityType, onPrintFinish }: PrintTableConfigurator) {
    printFunction(elementId, { windowTitle: i18n.t('Roltek projects') + ' - ' + entityType }, onPrintFinish);
}

export function generateSearchMessage(numberOfTotalItems: number) {
    if (numberOfTotalItems <= 0) {
        return i18n.t('Nije pronađen niti jedan rezultat');
    }

    if (numberOfTotalItems === 1) {
        return i18n.t('Pronađen jedan rezultat');
    }

    return (i18n.t(`Pronađeno je ukupno __NUMBEROFTOTALITEMS__ rezultata`) as string).replace(
        '__NUMBEROFTOTALITEMS__',
        String(numberOfTotalItems)
    );
}

function extractCreatedAtDateFromOrderedOffer(
    array: Offer[],
    columnOptionType: string,
    dateFormat: string,
    propertyToBeDisplayed: string
) {
    const orderedOffer = array.filter((offer) => offer.state === OfferStates.ORDERED);
    const importedOffer = array.filter((offer) => offer.state === OfferStates.IMPORTED);

    const offerToBeUsed = orderedOffer.length > 0 ? orderedOffer : importedOffer;
    const valueToBeDisplayed = offerToBeUsed.length > 0 ? offerToBeUsed[0][propertyToBeDisplayed] : null;

    return convertValueToDateIfNecessary(valueToBeDisplayed, columnOptionType, dateFormat);
}

export const ordersColumnData: ColumnOptions[] = [
    {
        key: 'orderNumber',
        name: AppTableColumn.title,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'clientName',
        name: AppTableColumn.client,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested contact',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'createdAt',
        name: AppTableColumn.createdAt,
        shouldSort: true,
        shouldFilter: false,
        type: 'date',
        badge: false,
    },
    {
        key: 'manufactureDate',
        name: AppTableColumn.manufactureDate,
        shouldSort: true,
        shouldFilter: false,
        type: 'date',
        badge: false,
    },
    {
        key: 'projectLabelName',
        name: AppTableColumn.state,
        shouldSort: true,
        shouldFilter: false,
        type: 'editable',
        badge: true,
        slotName: 'status',
    },
    {
        key: 'assignedUserName',
        name: AppTableColumn.assignedUser,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'price',
        name: AppTableColumn.price,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
    },
    {
        key: 'connection',
        name: AppTableColumn.connection,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
        ellipsis: true,
        isNarrowWidth: true,
    },
    {
        key: 'productsInfo',
        name: '',
        shouldSort: false,
        shouldFilter: false,
        type: 'editable',
        badge: true,
        slotName: 'expand',
    },
];

export const offersColumnData: ColumnOptions[] = [
    {
        key: 'offerName', //
        name: AppTableColumn.title,
        shouldSort: true,
        shouldFilter: false,
        type: 'string', // array last
        sortBy: 'createdAt',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'assignedUserName',
        name: AppTableColumn.assignedUser,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'createdAt',
        name: AppTableColumn.createdAt,
        shouldSort: true,
        shouldFilter: false,
        type: 'date',
        badge: false,
    },
    {
        key: 'projectLabelName',
        name: AppTableColumn.state,
        shouldSort: true,
        shouldFilter: false,
        type: 'editable',
        badge: true,
        slotName: 'status',
    },
    {
        key: 'clientName',
        name: AppTableColumn.client,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested contact',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'price',
        name: AppTableColumn.price,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
    },
    {
        key: 'connection',
        name: AppTableColumn.connection,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
        ellipsis: true,
        isNarrowWidth: true,
    },
];

export const requestColumnData: ColumnOptions[] = [
    {
        key: 'typeSpecificId',
        name: AppTableColumn.title,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        sortBy: 'createdAt',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'userGroupId',
        name: AppTableColumn.userGroup,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
        isNarrowWidth: true,
    },
    {
        key: 'client.priceType.name',
        name: AppTableColumn.priceType,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
        isNarrowWidth: true,
    },
    {
        key: 'client.priceListRegion.name',
        name: AppTableColumn.priceListRegion,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
        isNarrowWidth: true,
    },
    {
        key: 'madeByUser.name',
        name: AppTableColumn.madeBy,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'assignedUser.name',
        name: AppTableColumn.assignedUser,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'label.name',
        name: AppTableColumn.state,
        shouldSort: true,
        shouldFilter: false,
        type: 'editable',
        badge: true,
        slotName: 'status',
    },
    {
        key: 'connection',
        name: AppTableColumn.connection,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
        ellipsis: true,
        isNarrowWidth: true,
    },
    {
        key: 'sortDate',
        name: AppTableColumn.createdAt,
        shouldSort: true,
        shouldFilter: false,
        type: 'date',
        badge: false,
    },
    {
        key: 'client.name',
        name: AppTableColumn.client,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested contact',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'client.address.country.name',
        name: AppTableColumn.country,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
        isNarrowWidth: true,
    },
];

export const leadsColumnData: ColumnOptions[] = [
    {
        key: 'clientName',
        name: AppTableColumn.client,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested contact',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'createdAt',
        name: AppTableColumn.createdAt,
        shouldSort: true,
        shouldFilter: false,
        type: 'date',
        badge: false,
    },
    {
        key: 'projectLabelName',
        name: AppTableColumn.state,
        shouldSort: true,
        shouldFilter: false,
        type: 'editable',
        badge: true,
        slotName: 'status',
    },
    {
        key: 'assignedUserName',
        name: AppTableColumn.assignedUser,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'leadNumberOfProducts',
        name: AppTableColumn.numberOfProducts,
        shouldSort: true,
        shouldFilter: false,
        type: 'number nested',
        badge: false,
    },
];

export const adminColumnData: ColumnOptions[] = [
    {
        key: 'clientName',
        name: AppTableColumn.client,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'clientCity',
        name: AppTableColumn.city,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'createdAt',
        name: AppTableColumn.createdAt,
        shouldSort: true,
        shouldFilter: false,
        type: 'date',
        badge: false,
    },
    {
        key: 'contactPersonPhone',
        name: AppTableColumn.phone,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
        shouldBreakAll: true,
    },
    {
        key: 'leadNumberOfProducts',
        name: AppTableColumn.numberOfProducts,
        shouldSort: true,
        shouldFilter: false,
        type: 'number nested',
        badge: false,
    },
    {
        key: 'assignedUserName',
        name: AppTableColumn.assignedUser,
        shouldSort: false,
        shouldFilter: false,
        type: 'editable',
        badge: false,
        slotName: 'name',
    },
];

export const allColumnData: ColumnOptions[] = [
    {
        key: 'typeSpecificId',
        name: AppTableColumn.title,
        shouldSort: false,
        shouldFilter: false,
        type: 'string',
        sortBy: 'createdAt',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'clientName',
        name: AppTableColumn.client,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested contact',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'createdAt',
        name: AppTableColumn.createdAt,
        shouldSort: true,
        shouldFilter: false,
        type: 'date',
        badge: false,
    },
    {
        key: 'projectLabelName',
        name: AppTableColumn.state,
        shouldSort: true,
        shouldFilter: false,
        type: 'editable',
        badge: true,
        isNarrowWidth: true,
        slotName: 'status',
    },
    {
        key: 'assignedUserName',
        name: AppTableColumn.assignedUser,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'price', // 'selectedOffer.price',
        name: AppTableColumn.price,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
    },
    {
        key: 'connection',
        name: AppTableColumn.connection,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
        ellipsis: true,
        isNarrowWidth: true,
    },
    {
        key: 'productsInfo',
        name: '',
        shouldSort: false,
        shouldFilter: false,
        type: 'editable',
        badge: true,
        slotName: 'expand',
    },
];

export const clientColumnData: ColumnOptions[] = [
    {
        key: 'id',
        name: AppTableColumn.title,
        shouldSort: true,
        shouldFilter: false,
        type: 'number',
        badge: false,
        isNarrowWidth: true,
    },
    {
        key: 'name',
        name: AppTableColumn.client,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested contact',
        badge: false,
        shouldBreakNone: true,
    },
    {
        key: 'address.streetNameAndNumber',
        name: AppTableColumn.address,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'address.poNumber',
        name: AppTableColumn.postalCode,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
        isNarrowWidth: true,
    },
    {
        key: 'address.city',
        name: AppTableColumn.city,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
    },
    {
        key: 'address.country.name',
        name: AppTableColumn.country,
        shouldSort: true,
        shouldFilter: false,
        type: 'string nested',
        badge: false,
        isNarrowWidth: true,
    },
    {
        key: 'taxId',
        name: AppTableColumn.taxId,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
    },
    {
        key: 'visibleId',
        name: AppTableColumn.ingeniousId,
        shouldSort: true,
        shouldFilter: false,
        type: 'string',
        badge: false,
    },
];
