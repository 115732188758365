
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import OfferItemsTable from '@/components/views/project/OfferItemsTable.vue';
import ProductsActions from '@/components/views/NewProjectView/Offer/ProductsActions.vue';
import Offer from '@/models/Offer';
import TotalPriceRow from '@/components/views/NewProjectView/Offer/TotalPriceRow.vue';
import { Icon, Spin } from 'ant-design-vue';
import ProductPicker from '@/components/views/NewProjectView/Offer/ProductPicker.vue';
import AddOfferTitles from '@/components/views/NewProjectView/Offer/AddOfferTitles.vue';
import CustomOrderDetails from '@/components/views/NewProjectView/Offer/CustomOrderDetails.vue';
import CustomOrderForm from '@/components/views/NewProjectView/Offer/CustomOrderForm.vue';

@Component({
    name: 'ProductsCustomOrder',
    components: {
        ProductPicker,
        TotalPriceRow,
        ProductsActions,
        OfferItemsTable,
        Spin,
        Icon,
        AddOfferTitles,
        CustomOrderDetails,
        CustomOrderForm,
    },
})
export default class ProductsCustomOrder extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ required: true }) private lockEditing!: boolean;

    public async saveForm() {
        const customOrderForm = this.$refs.customOrderForm as any;

        await customOrderForm.onSubmit();
    }
}
