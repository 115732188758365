import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { ChecklistSegment } from '@/interfaces/components/configurator/ChecklistSegment';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import { FieldTypes } from '@/enums/components/FieldTypes';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { UpdateFunctionEntry } from '@/interfaces/components/configurator/UpdateFunctionEntry';
import ConfiguratorService from '@/services/ConfiguratorService';
import { OptionFunctionEntry } from '@/interfaces/components/configurator/OptionFunctionEntry';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import store from '@/store';
import { ProductAndProductFormId } from '@/interfaces/general/ProductAndProductFormId';
import { PASTED_FIELD_PIDS } from '@/enums/global/GlobalOptions';
import { ProductFormAPI } from '@/api/ProductFormAPI';
import Product from '@/models/Product';
import { CustomColors } from '@/enums/global/CustomColors';
import { getRandomElementFromObj as randomize, findKeyByValue } from '@/helpers/ArrayHelper';
import { H } from '@fullcalendar/core/internal-common';

export function pIdsToUpdateForMultipositionPaste() {
    return PASTED_FIELD_PIDS;
}

/**
 * Converts the product from object values to an array of objects with id/value pairs
 * @param activeProductFormValues - A object containing key/value pairs of all the active product form values
 * @return - The array of objects with id/value pairs
 */
export function formatFormData(activeProductFormValues: ActiveProductFormValueObject) {
    const formData: Array<{ id: string; value: ConfiguratorValue }> = [];
    Object.entries(activeProductFormValues).forEach((formEntry) => {
        formData.push({
            id: formEntry[0] as string,
            value: formEntry[1] as ConfiguratorValue,
        });
    });

    return formData;
}

/**
 * Converts the array of id/value pairs to an object with key/value pairs
 * @param formData - The array of id/value pairs
 * @return - The object containing all data as key/value pairs
 */
export function formatProductFormValues(formData: ChecklistFieldEntry[]) {
    const productFormValues: ActiveProductFormValueObject = {};
    formData.forEach((formEntry: ChecklistFieldEntry) => {
        productFormValues[formEntry.id] = formEntry.value;
    });

    return productFormValues;
}

export async function extractDefaultValues({ productForm, productId, clientId, productSystemName }: any) {
    const formData: { [key: string]: string | number | boolean } = {};
    const checkListFields = productForm.checklists.checklistSegments
        .map((checklistSegment: ChecklistSegment) => checklistSegment.checklistFields)
        .flat();
    for (let i = 0; i < checkListFields.length; i++) {
        formData[checkListFields[i].pId] = getAppropriateDefaultValue(checkListFields[i]);
    }
    if (productId) {
        const productDefaultFields = await updateDefaultProductFields(productId, clientId, productSystemName);

        productDefaultFields.forEach((entry: ChecklistFieldEntry) => {
            formData[entry.id] = entry.value;
        });
    }

    return formData;
}

export function extractWarningAndAbortFields(activeProductForm: ProductForm) {
    return activeProductForm.checklists.checklistSegments[0].checklistFields.filter(
        (checklistField: ChecklistField) => {
            return checklistField.fieldType === 'warning' || checklistField.fieldType === 'abort';
        }
    );
}

async function updateDefaultProductFields(
    productId: string,
    clientId: string | undefined | null,
    productSystemName: string | null
) {
    let productDefaultFields;

    try {
        productDefaultFields = await ConfiguratorService.getDefaultProductFields(
            productId,
            clientId,
            productSystemName
        );
    } catch (e) {
        return Promise.reject();
    }

    return Promise.resolve(productDefaultFields);
}

export function extractUpdateFunctions(productForm: ProductForm): UpdateFunctionEntry[] {
    const updateFunctions: UpdateFunctionEntry[] = [];

    productForm.checklists.checklistSegments.forEach((checklistSegment: ChecklistSegment) => {
        checklistSegment.checklistFields.forEach((checklistField: ChecklistField) => {
            if (
                checklistField.checklistUpdateFunctions != null &&
                Array.isArray(checklistField.checklistUpdateFunctions) &&
                checklistField.checklistUpdateFunctions.length > 0
            ) {
                updateFunctions.push({
                    id: checklistField.pId,
                    type: checklistField.fieldType,
                    updateFunctions: checklistField.checklistUpdateFunctions,
                });
            }
        });
    });

    return updateFunctions;
}

export function extractOptionsFunctions(productForm: ProductForm): OptionFunctionEntry[] {
    const optionsFunctions: OptionFunctionEntry[] = [];

    const dropdownChecklistFields = productForm.checklists.checklistSegments
        .map((checklistSegment: ChecklistSegment) => checklistSegment.checklistFields)
        .flat()
        .filter((checklistField) => checklistField.fieldType === 'dropdown');

    dropdownChecklistFields.forEach((checklistField) => {
        if (
            checklistField == null ||
            checklistField.checklistDropdownField == null ||
            checklistField.checklistDropdownField.optionsFunction == null ||
            checklistField.checklistDropdownField.optionsFunctionData == null
        ) {
            return;
        }

        optionsFunctions.push({
            optionsFunction: checklistField.checklistDropdownField.optionsFunction,
            optionsFunctionData: checklistField.checklistDropdownField.optionsFunctionData,
            id: checklistField.pId,
        });
    });

    return optionsFunctions;
}

/**
 * Extracts the default value based on the checklist field type
 * @param checklistField - The checklist field to have the default value extracted from
 * @return - The default value
 */
export function getAppropriateDefaultValue(checklistField: ChecklistField) {
    let defaultValue: ConfiguratorValue = '';

    switch (checklistField.fieldType) {
        case FieldTypes.boolean:
            defaultValue = checklistField.checklistBoolField ? checklistField.checklistBoolField.defaultValue : false;
            break;
        case FieldTypes.string:
            if (checklistField.checklistStringField) {
                defaultValue = checklistField.checklistStringField.defaultValue || '';
            }
            break;
        case FieldTypes.integer:
            defaultValue = checklistField.checklistIntegerField ? checklistField.checklistIntegerField.defaultValue : 0;
            break;
        case FieldTypes.float:
            defaultValue = checklistField.checklistFloatField ? checklistField.checklistFloatField.defaultValue : 0;
            break;
        case FieldTypes.dropdown:
            const dropdownDefaultValue =
                checklistField.checklistDropdownField &&
                checklistField.checklistDropdownField.optionsFunction == null &&
                checklistField.checklistDropdownField.checklistDropdownOptions.length > 0
                    ? checklistField.checklistDropdownField.checklistDropdownOptions[0].vId
                    : '#';
            defaultValue = dropdownDefaultValue;
            break;
        default:
            defaultValue = '';
    }
    return defaultValue;
}

/**
 * Checks that the given two product form value objects are the same
 * @param firstProductFormValues - one active product form value object
 * @param secondProductFormValues - other active product form value object
 * @return - A boolean determining if the product form value objects are the same
 */
export function areTwoProductFormValuesSame(
    firstProductFormValues: ActiveProductFormValueObject,
    secondProductFormValues: ActiveProductFormValueObject
) {
    const arrayProductFormValues = Object.entries(firstProductFormValues);
    let areProductFormValuesSame = true;

    for (let i = 0; i < arrayProductFormValues.length; i++) {
        const firstValue = arrayProductFormValues[i][1];
        const secondValue = secondProductFormValues[arrayProductFormValues[i][0]];

        if (typeof firstValue === 'object') {
            continue;
        }

        if (firstValue !== secondValue) {
            areProductFormValuesSame = false;
            break;
        }
    }

    return areProductFormValuesSame;
}

/**
 * Tries to convert a string to an array of arrays containing cell data.
 * @param {string} pastedString - The string extracted from clipboard data
 * @return {array} An array of arrays containing the pasted cell data
 */
export function extractCellsAsArraysFromString(pastedString: string) {
    const rows: string[] = pastedString
        .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, (match: string, p1: string) =>
            p1.replace(/""/g, '"').replace(/\r\n|\n\r|\n|\r/g, ' ')
        )
        .split(/\r\n|\n\r|\n|\r/g);

    return rows
        .filter((row) => row !== '')
        .map((row) => {
            return row.split(/\t/g).map((cell) => {
                let value: string | number = cell.replace(',', '.');
                if (!isNaN(Number(value)) && value !== '') {
                    value = Number(value);
                }

                return value;
            });
        });
}

/**
 * Returns a boolean whether .csv data or single value is pasted
 * @param {array} cells - array of cell values
 * @return {boolean} Returns true if a single value is passed
 */
export function determineIfSingleValueIsPasted(cells: Array<Array<string | number>>) {
    return cells.length !== 1 || cells[0].length !== 1;
}

/**
 * Determine which existing rows need to be updated
 * @param {array} activeItemRows - array of multiposition rows
 * @param {array} cells - array of cell values
 * @param {string|undefined} startingRowNumber - row number where the data was pasted
 */
export function updateExistingRowsWithPastedData(
    activeItemRows: ProductRowItem[],
    cells: Array<Array<string | number>>,
    startingRowNumber?: number
) {
    const activeItemRowsToBeUpdated =
        startingRowNumber == null ? activeItemRows : activeItemRows.slice(startingRowNumber);

    activeItemRowsToBeUpdated.forEach((activeItemRow, index) => {
        updateActiveRowItemProductFormValues(activeItemRow, cells, index);
    });
}

/**
 * Updates existing active product form values with pasted data
 * @param {array} activeItemRow - multiposition row which data will be updated
 * @param {array} cells - array of cell values
 * @param {number} index - index in cells array from which data is fetched
 */
function updateActiveRowItemProductFormValues(
    activeItemRow: ProductRowItem,
    cells: Array<Array<string | number>>,
    index: number
) {
    const row = cells[index];

    if (row == null) {
        return;
    }

    pIdsToUpdateForMultipositionPaste().forEach((pId, pIdIndex) => {
        let value: string | number = row[pIdIndex];
        const editableField = activeItemRow.editableFields.find((field: any) => {
            return field.pId === pId;
        });

        if (editableField?.fieldType === 'string') {
            value = value ? String(value) : '';
        }

        store.dispatch('configurator/updateActiveProductFormValue', {
            pId,
            value,
            productFormId: activeItemRow.activeProductFormId,
        });
    });
}

/**
 * Creates new remaining rows from pasted data
 * @param {array} cells - array of cell values
 * @param {array} activeItemRows - array of multiposition rows
 * @param {function} addNewRow - function for adding new rows when adding products
 * @param {function} addNewRowInEditMode - function for adding new rows when editing products
 * @param {string|undefined} startingRowNumber - row number where the data was pasted
 */
export function createNewRowsWithPastedData(
    cells: Array<Array<string | number>>,
    activeItemRows: ProductRowItem[],
    addNewRow: (n: number, existingProductValues: ChecklistFieldEntry[]) => void | null,
    addNewRowInEditMode: (
        firstActiveItemRow: ProductRowItem,
        existingProductValues: ChecklistFieldEntry[]
    ) => void | null,
    startingRowNumber?: number
) {
    if (addNewRow == null && addNewRowInEditMode == null) {
        throw new Error('No add functions are present when generating new rows.');
    }

    const numberOfCellsThatWerePasted =
        startingRowNumber == null ? activeItemRows.length : activeItemRows.length - Number(startingRowNumber);

    cells.splice(0, numberOfCellsThatWerePasted);

    if (cells.length <= 0) {
        return;
    }

    cells.forEach((row) => {
        const existingProductValues = pIdsToUpdateForMultipositionPaste().map((pId, pIdIndex) => {
            return {
                id: pId,
                value: row[pIdIndex],
            };
        });
        if (addNewRowInEditMode !== null) {
            addNewRowInEditMode(activeItemRows[0], existingProductValues);
        } else {
            addNewRow(1, existingProductValues);
        }
    });
}

/**
 * Filters, sorts and groups active item rows
 * @param {array} productFormAndProductIds - an array of objects
 * with product form id and product id that are currently active
 * @param {array} activeItemRows - array of multiposition rows
 * @param {array} savedSegmentProductIds - array of saved rows that show not be visible
 * @return {array} filteredActiveItemRows - grouped and sorted active item rows (by product form id)
 */
export function filterAndSortActiveItemRows(
    productFormAndProductIds: ProductAndProductFormId[],
    activeItemRows: ProductRowItem[],
    savedSegmentProductIds: string[]
) {
    const productFormIds = [
        ...new Set(
            productFormAndProductIds.map(
                (productFormAndProductId: ProductAndProductFormId) => productFormAndProductId.productFormId
            )
        ),
    ].filter((id: string) => !savedSegmentProductIds.some((savedProductId) => savedProductId === id)) as string[];

    const filteredItemRows: ProductRowItem[][] = groupActiveItemRowsByProductForms(productFormIds, activeItemRows);

    return sortGroupedActiveItemRows(filteredItemRows.slice());
}

/**
 * Groups active item rows by product form id
 * @param {array} productFormIds - unique product from ids that are currently active
 * @param {array} activeItemRows - array of multiposition rows
 * @return {array} filteredActiveItemRows - grouped active item rows
 */

function groupActiveItemRowsByProductForms(productFormIds: string[], activeItemRows: ProductRowItem[]) {
    const filteredItemRows: ProductRowItem[][] = [];
    productFormIds.forEach((productFormId: string) => {
        filteredItemRows.push(
            activeItemRows.filter((activeItemRow, index) => {
                return activeItemRow.productFormId === productFormId;
            })
        );
    });

    return filteredItemRows;
}

/**
 * Sorts the grouped item rows by row number
 * @param {array} filteredItemRows - unique product from ids that are currently active
 * @return {array} sortedActiveItemRows - sorted active item rows by row numbers
 */

function sortGroupedActiveItemRows(filteredItemRows: ProductRowItem[][]) {
    const colors: Record<string | number, any> = { ...CustomColors };

    for (let i = 0; i < filteredItemRows.length; i++) {
        const filteredItemRow = filteredItemRows[i];
        filteredItemRow.sort((a, b) => {
            if (a.offerItem == null || b.offerItem == null) {
                return 1;
            }

            return a.offerItem.rowNumber - b.offerItem.rowNumber;
        });

        // Assign color values to rows that have connection to other rows (combinations)
        filteredItemRow.forEach((row, index) => {
            row.segmentIndex = index;
            setRowColor(row, colors);
        });
    }

    return filteredItemRows;
}

export function setRowColor(row: ProductRowItem, colors: Record<string | number, any>) {
    if (row.connection === null) {
        return;
    }

    if (colors[row.connection]) {
        row.color = colors[row.connection];
        return;
    }

    const randomColor = randomize(colors);
    const colorKey = findKeyByValue(colors, randomColor);
    colors[row.connection] = randomColor;

    if (colorKey) {
        delete colors[colorKey];
    }

    row.color = randomColor;
}

export async function fetchProductItems(productIds: string[]) {
    const promises = [];
    const products: ProductForm[] = [];

    for (const productId of [...new Set(productIds)]) {
        promises.push(
            ProductFormAPI.getFromProduct(productId).then((data) => {
                const productForm = data.productForm! as ProductForm;

                Product.insertOrUpdate({
                    data,
                    insertOrUpdate: ['image', 'uploadedPdf'],
                });

                store.commit('configurator/addProductForm', productForm);
            })
        );
    }

    try {
        await Promise.all(promises);
    } catch (e) {
        return Promise.reject();
    }

    return Promise.resolve(products);
}

export function cssMultipositionRowElementPositioning(rowElement: HTMLElement | null) {
    if (!rowElement) {
        return;
    }

    const systemElement = rowElement
        .querySelector('[data-test-id="p7405"]')
        ?.closest('.is-multiposition') as HTMLElement;

    const widthElement = rowElement
        .querySelector('[data-test-id="p7402"]')
        ?.closest('.is-multiposition') as HTMLElement;

    const heightElement = rowElement
        .querySelector('[data-test-id="p7403"]')
        ?.closest('.is-multiposition') as HTMLElement;

    // Fix css by adjusting each element of the first 5 elements by the distance they are from the first leftmost element
    if (
        !systemElement.classList.contains('is-multiposition--visible') ||
        !widthElement.classList.contains('is-multiposition--visible') ||
        !heightElement.classList.contains('is-multiposition--visible')
    ) {
        setMultipositionRowElementPositioning(rowElement);
    }
}

export function setMultipositionRowElementPositioning(rowElement: HTMLElement) {
    const rowFields = rowElement.getElementsByClassName('is-multiposition--visible') as HTMLCollectionOf<HTMLElement>;

    if (!rowFields.length) {
        return;
    }

    let rowLeft = 112; // Reset for each row
    for (let i = 0; i < 5; i++) {
        const element = rowFields[i];

        if (i === 0) {
            element.style.left = '112px';
        } else {
            if (element) {
                element.style.left = `${rowLeft}px`;
            }
        }

        rowLeft += element?.offsetWidth || 0; // Adjust margin as needed
    }
}
