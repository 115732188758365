
import { Component, Prop, Vue } from 'vue-property-decorator';
import Offer from '@/models/Offer';

@Component({
    name: 'CustomOrderDetails',
    components: {},
})
export default class CustomOrderDetails extends Vue {
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private get description() {
        return this.selectedOffer?.offerNote.length ? this.selectedOffer.offerNote[0].note : '-';
    }
}
