
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrderNotesDetails from '@/components/views/NewProjectView/Offer/OrderNotesDetails.vue';
import OrderNotesForm from '@/components/views/NewProjectView/Offer/OrderNotesForm.vue';
import Offer from '@/models/Offer';

@Component({
    name: 'OrderNotesDetailsAndForm',
    components: { OrderNotesForm, OrderNotesDetails },
})
export default class OrderNotesDetailsAndForm extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ required: true }) private selectedOffer!: Offer;

    public async saveForm() {
        const installationAndDeliveryForm = this.$refs.orderNotesForm as OrderNotesForm;

        await installationAndDeliveryForm.onSubmit();
    }
}
