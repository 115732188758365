import Store from '@/store';
import { ColorRequestConfig } from '@/interfaces/components/color/ColorRequestConfig';
import { ColorFilters } from '@/interfaces/components/color/ColorFilters';

export const FiltersConfiguration = [
    {
        options: () => [
            { description: 'RAL', name: 'RAL', id: 1 },
            { description: 'NCS', name: 'NCS', id: 2 },
            { description: 'Other', name: 'Other', id: 3 },
        ],
        label: 'Color system',
        type: 'colorSystem',
        fieldType: 'dropdown',
        isMandatory: true,
        isActive: (activeTab: string) => (activeTab === 'color-search' ? false : true),
    },
    {
        options: () => Store.getters['colorData/charts'],
        label: 'Color system name',
        type: 'colorSystemName',
        fieldType: 'text',
        isMandatory: true,
        isActive: (activeTab: string) => (activeTab === 'color-search' ? false : true),
    },
    {
        options: () => [],
        label: 'Color collection',
        type: 'colorCollection',
        fieldType: 'dropdown',
        isMandatory: true,
        isActive: () => false,
    },
    {
        options: () => Store.getters['colorData/glosses'],
        label: 'Gloss',
        type: 'colorGloss',
        fieldType: 'dropdown',
        isMandatory: false,
        isActive: () => true,
    },
    {
        options: () => Store.getters['colorData/surfaces'],
        label: 'Surface',
        type: 'colorSurface',
        fieldType: 'dropdown',
        isMandatory: false,
        isActive: () => true,
    },
    {
        options: () => Store.getters['colorData/effects'],
        label: 'Effect',
        type: 'colorEffect',
        fieldType: 'dropdown',
        isMandatory: false,
        isActive: () => true,
    },
    {
        options: () => Store.getters['colorData/qualities'],
        label: 'Quality',
        type: 'colorQuality',
        fieldType: 'dropdown',
        isMandatory: false,
        isActive: (activeTab: string) => (activeTab === 'color-search' ? false : true),
    },
];

export const formatCustomColor = function (colorConfig: ColorRequestConfig, colorFilters: ColorFilters) {
    const values = [
        colorConfig?.colorName,
        colorConfig?.colorProducer,
        colorFilters?.colorSystem?.value,
        colorFilters?.colorSystemName?.value,
        colorConfig?.powderID,
        colorFilters?.colorCollection?.value,
        colorFilters?.colorGloss?.value,
        colorFilters?.colorSurface?.value,
        colorFilters?.colorEffect?.value,
        colorFilters?.colorQuality?.value,
    ];

    return values.filter((value) => value).join(' | ');
};

export const formatColorSearchQuery = function (filters: ColorFilters, searchText: string = '') {
    let query = '';

    if (searchText) {
        query += `&search=${searchText}`;
    }

    if (filters) {
        for (const key in filters) {
            if (!filters[key as keyof ColorFilters]?.id) {
                continue;
            }

            if (filters[key as keyof ColorFilters] && key !== 'colorSystem') {
                query += `&filter[${key}.id]=${filters[key as keyof ColorFilters]?.id}`;
            }
        }
    }

    return query;
};

export const formatSelectedColorForCustomRequest = function (selectedColor: any) {
    const { name, externalId, powders } = selectedColor;
    const powder = powders?.[0];
    const powderExternalId = powder?.externalId;
    const materialOid = powder?.material?.oid;

    return [name, externalId, powderExternalId, materialOid].filter(Boolean).join(' | ');
};
