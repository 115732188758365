import { Model } from '@vuex-orm/core';
import ColorApi from '@/api/ColorAPI';

const cacheTTL = 60 * 60 * 24 * 1000; // 1 day in milliseconds

export default class Color extends Model {
    public static entity = 'color';

    public static fields() {
        return {
            id: this.attr(''),
            name: this.attr(''),
        };
    }

    public static async getAll(query: string = '') {
        const cacheName = 'colors-cache'; // Cache name for the colors data
        const cacheKey = query || 'default'; // Use query as cache key or 'default' for no-query case

        let colors;

        if (!query) {
            try {
                const cache = await caches.open(cacheName);
                // Check if the data is already in the cache

                const cachedResponse = await cache.match(cacheKey);
                const now = new Date().getTime();

                if (cachedResponse) {
                    const cachedDate = cachedResponse.headers.get('date') as string;
                    const cacheAge = now - new Date(cachedDate).getTime();

                    if (cacheAge < cacheTTL) {
                        return await cachedResponse.json(); // Return cached data if available
                    }
                }

                // Fetch the data using your separate logic
                colors = await ColorApi.getAll(query);
                const data = colors.data;

                // Set headers to track time
                const headers = new Headers();
                headers.set('date', new Date().toISOString());

                // Store the fetched data in the cache
                cache.put(cacheKey, new Response(JSON.stringify(data), { headers }));
                return data;
            } catch (error) {
                console.error('Error handling cache or fetching data:', error);
                return Promise.reject(error);
            }
        }

        // If a query is provided, fetch data directly (no caching for now)
        try {
            colors = await ColorApi.getAll(query);
            return Promise.resolve(colors.data);
        } catch (error) {
            console.error('Error fetching data with query:', error);
            return Promise.reject(error);
        }
    }

    public static async getGeneralColors(query: string = '') {
        let colors;

        try {
            colors = await ColorApi.getGeneralColors(query);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(colors.data);
    }

    public static async getPowders() {
        let powders;

        try {
            powders = await ColorApi.getPowders();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(powders.data);
    }

    public static async getPowderPrices() {
        let prices;

        try {
            prices = await ColorApi.getPowderPrices();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(prices.data);
    }

    public static async getSurfaces() {
        let surfaces;

        try {
            surfaces = await ColorApi.getSurfaces();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(surfaces.data);
    }

    public static async getQualities() {
        let qualities;

        try {
            qualities = await ColorApi.getQualities();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(qualities.data);
    }

    public static async getGlosses() {
        let glosses;

        try {
            glosses = await ColorApi.getGlosses();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(glosses.data);
    }

    public static async getEffects() {
        let effects;

        try {
            effects = await ColorApi.getEffects();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(effects.data);
    }

    public static async getColorCharts() {
        let charts;

        try {
            charts = await ColorApi.getCharts();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(charts.data);
    }

    public id!: string;
    public name!: string;
}
