
import { Component, Prop } from 'vue-property-decorator';
import { Button, Dropdown, Icon, Menu } from 'ant-design-vue';
import { OfferHistoryTableItem } from '@/interfaces/components/offerHistory/OfferHistoryTableItem';
import { generateItemActions } from '@/helpers/OfferHistoryTableHelper';
import Client from '@/models/Client';
import OfferRepository from '@/repositories/OfferRepository';
import { onDownloadPDF } from '@/helpers/OfferHelper';
import { OfferStates } from '@/enums/global/OfferStates';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { OfferHistoryTableActionItemData } from '@/interfaces/components/offerHistory/OfferHistoryTableActionItemData';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { UserRepository } from '@/repositories/UserRepository';

@Component({
    name: 'OfferHistoryTableActions',
    components: { Button, Dropdown, Menu, MenuItem: Menu.Item, Icon },
})
export default class OfferHistoryTableActions extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private tableOfferItem!: OfferHistoryTableItem;
    @Prop({ default: null }) private client!: Client | null;
    @Prop({ default: null }) private projectId!: string | null;
    @Prop({ default: false }) private isProjectLocked!: boolean;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ default: false }) private isPreviewMode!: boolean;
    @Prop({ default: false }) private isProjectReadOnly!: boolean;
    @Prop({ default: false }) private isProjectInRequestState!: boolean;
    @Prop({ default: false }) private isProjectFromAnotherUserGroup!: boolean;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get allItemActions() {
        return generateItemActions({
            isOfferLocked: this.tableOfferItem.hasPDF,
            isProjectLocked: this.isProjectLocked,
            isProjectReadOnly: this.isProjectReadOnly,
            isProjectInRequestState: this.isProjectInRequestState,
            offerHasErrors: this.tableOfferItem.hasErrors,
            canUserSendOrders: this.canUserSendOrders,
            offerItemsExist: this.tableOfferItem.offerItemsExist,
            isOfferInDraftState: this.tableOfferItem.offerState === OfferStates.DRAFT,
            isOfferInRequestState: this.tableOfferItem.assistanceRequired,
            isProjectFromAnotherUserGroup: this.isProjectFromAnotherUserGroup,
            canUserDeleteOffers: this.canUserDeleteOffers,
            isCurrentUserTechnologist: this.isCurrentUserTechnologist,
        });
    }

    private get isCurrentUserTechnologist() {
        return UserRepository.isCurrentUserTechnologist();
    }

    private get isAnyItemActionVisible() {
        return this.allItemActions.some((itemAction) => itemAction.isVisible);
    }

    private async proceedWithOfferAction(args: {
        params: OfferHistoryTableActionItemData;
        key: number;
        callback: (args: OfferHistoryTableActionItemData) => void;
    }) {
        if (args.key === 5) {
            this.$confirm({
                title: this.$t('Are you sure you want to delete this offer?') as string,
                content: '',
                okText: this.$t('Yes') as string,
                cancelText: this.$t('Cancel') as string,
                onOk: () => {
                    args.callback(args.params);
                },
                okType: 'danger',
            });
        } else {
            this.loadingOverlay.start();
            await args.callback(args.params);
            this.loadingOverlay.stop();
        }
    }

    private onDownloadOfferPDF() {
        const offer = OfferRepository.getOfferById(this.tableOfferItem.id);
        try {
            onDownloadPDF(offer, this.client);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        }
    }
}
