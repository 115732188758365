import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class OfferNoteAPI {
    public static async getAll() {
        let offerNotes;

        try {
            offerNotes = (await ApiController.get(ApiRoutes.offerNote.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerNotes);
    }

    public static async getById(offerId: string) {
        let offerNote;

        try {
            offerNote = (await ApiController.get(ApiRoutes.offerNote.getById(offerId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerNote);
    }

    public static async update(payload: any) {
        let offerNote;

        const request = {
            data: {
                type: 'offer_notes',
                id: String(payload.id),
                attributes: {
                    note: payload.note,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: String(payload.offerId),
                        },
                    },
                },
            },
        };

        try {
            offerNote = (await ApiController.patch(ApiRoutes.offerNote.update(payload.id), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerNote);
    }

    public static async create(payload: any) {
        let offerNote;

        const request = {
            data: {
                type: 'offer_notes',
                attributes: {
                    note: payload.note,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: String(payload.offerId),
                        },
                    },
                },
            },
        };

        try {
            offerNote = (await ApiController.post(ApiRoutes.offerNote.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerNote);
    }

    public static async delete(offerNoteId: string) {
        let offerNote;

        try {
            offerNote = (await ApiController.delete(ApiRoutes.offerNote.delete(offerNoteId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerNote);
    }
}
