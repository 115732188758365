import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';
import Vue from 'vue';
import { generateStateSpecificId, extractIdOfNewestOffer } from '@/helpers/project/ProjectHelper';

const mutations: MutationTree<ILocalState> = {
    updateProjects(state, payload) {
        state.projects = payload;
    },
    updateProject(state, payload) {
        const { project, patchType } = payload;
        const projectIndex = state.projects.findIndex((p) => p.id === project.id);

        if (patchType === 'Label') {
            if (projectIndex >= 0) {
                state.projects[projectIndex] = {
                    ...state.projects[projectIndex],
                    projectLabelColor: project.label?.color ?? '',
                    projectLabelName: project.label?.name ?? '',
                } as any;
            }

            return;
        }

        const typeSpecificId = generateStateSpecificId(project as any);
        const newestOfferId = extractIdOfNewestOffer(project as any);

        const formattedPayload = {
            id: project.id,
            assignedUserId: project?.assignedUser?.id,
            assignedUserName: project?.assignedUser?.name,
            clientId: project.client.id,
            clientName: project.client.name,
            clientPhone: project.client?.address?.phone,
            clientPoNumber: project.client?.address?.poNumber,
            clientCity: project.client?.address?.city,
            clientStreetNameAndNumber: project.client?.address?.streetNameAndNumber,
            contactPersonPhone: project?.contactPerson?.phone,
            createdAt: project.createdAt,
            manufactureDate: project.manufactureDate,
            state: project.state,
            projectLabelColor: project.label.color,
            projectLabelName: project.label.name,
            orderNumber: project.orderNumber,
            price: project.offerRevisions[0]?.sellingOfferPrice?.price,
            typeSpecificId,
            newestOfferId,
        };

        if (projectIndex >= 0) {
            state.projects[projectIndex] = { ...state.projects[projectIndex], ...formattedPayload } as any;
        }
    },
    updateNextSteps(state, payload) {
        state.nextSteps = payload;
    },
    updateNextStep(state, payload) {
        const index = state.nextSteps.findIndex((item) => item.id === payload.id);

        if (index !== -1) {
            Vue.set(state.nextSteps, index, payload);
        }
    },
    removeNextStep(state, payload) {
        const nextStepIndex = state.nextSteps.findIndex((ns) => ns.id === payload);
        if (nextStepIndex >= 0) {
            state.nextSteps.splice(nextStepIndex, 1);
        }
    },
    updateClients(state, payload) {
        state.clients = payload;
    },

    clearProjectNavigationSettings(state) {
        state.projectNavigationSettings.sortedProjectsObject = {};
        state.projectNavigationSettings.userId = '';
        state.projectNavigationSettings.entityType = '';
        state.projectNavigationSettings.isLastPageFetched = false;
        state.projectNavigationSettings.isFirstPageFetched = false;
    },
    updateProjectNavigationSettings(state, settings) {
        const { projectIds, page, hasNext, hasPrev, userId, entityType } = settings;

        if (projectIds) {
            state.projectNavigationSettings.sortedProjectsObject[page] = projectIds;
        }
        if (userId) {
            state.projectNavigationSettings.userId = userId;
        }
        if (entityType) {
            state.projectNavigationSettings.entityType = entityType;
        }

        if (!state.projectNavigationSettings.isFirstPageFetched && hasPrev !== undefined) {
            state.projectNavigationSettings.isFirstPageFetched = !hasPrev;
        }

        if (!state.projectNavigationSettings.isLastPageFetched && hasNext !== undefined) {
            state.projectNavigationSettings.isLastPageFetched = !hasNext;
        }
        // LocalStorageService.save('projectNavigationSettings', JSON.stringify(settings));
    },
};

export default mutations;
