import { ActionTree } from 'vuex';
import ILocalState from './stateInterface';
import Project from '@/models/Project';
import NextStep from '@/models/NextStep';
import Client from '@/models/Client';
import { LocalStorageService } from '@/services/LocalStorageService';
import { saveSearchParameters } from '@/helpers/SearchHelper';
import {
    determineNextAndPreviousProjectInformation,
    fetchCorrespondingPageAndUpdateStore,
} from '@/helpers/project/ProjectNavigationHelper';
import { CorrespondingProjectSettings } from '@/interfaces/components/project/CorrespondingProjectSettings';

const actions: ActionTree<ILocalState, {}> = {
    async updateProjects({ commit }, { sortedProjectIds, projects }: any) {
        const sortedProjects = projects
            .slice()
            .sort((a: Project, b: Project) => sortedProjectIds.indexOf(a.id) - sortedProjectIds.indexOf(b.id));

        commit('updateProjects', sortedProjects);
    },
    async updateProject({ commit }, { project, patchType }: any) {
        commit('updateProject', { project, patchType });
    },

    async updateNextSteps({ commit }, { sortedNextStepIds, nextSteps }: any) {
        const sortedNextSteps = nextSteps
            .slice()
            .sort((a: NextStep, b: NextStep) => sortedNextStepIds.indexOf(a.id) - sortedNextStepIds.indexOf(b.id));

        commit('updateNextSteps', sortedNextSteps);
    },

    async removeNextStep({ commit }, { nextStepId }: any) {
        commit('removeNextStep', nextStepId);
    },

    async updateClients({ commit }, { sortedClientIds, clients }: any) {
        const sortedClients = clients
            .slice()
            .sort((a: Client, b: Client) => sortedClientIds.indexOf(a.id) - sortedClientIds.indexOf(b.id));

        commit('updateClients', sortedClients);
    },

    async updateProjectNavigationSettings({ commit }, projectNavigationSettingsResponse: any) {
        commit('updateProjectNavigationSettings', projectNavigationSettingsResponse);
    },
    isPreviousButtonDisabled({ getters }, currentProjectId) {
        const sortedProjectsObject = getters.sortedProjectsObject;
        const sortedProjectsIds = Object.values(sortedProjectsObject).flat();

        const currentProjectIdIndex = sortedProjectsIds.findIndex((projectId) => projectId === currentProjectId);
        const previousProjectId = sortedProjectsIds[currentProjectIdIndex - 1];

        if (previousProjectId != null) {
            return false;
        }

        return getters.isFirstPageFetched;
    },

    isNextButtonDisabled({ getters, state }, currentProjectId) {
        const sortedProjectsObject = getters.sortedProjectsObject;
        const sortedProjectsIds = Object.values(sortedProjectsObject).flat();

        const currentProjectIdIndex = sortedProjectsIds.findIndex((projectId) => projectId === currentProjectId);
        const nextProjectId = sortedProjectsIds[currentProjectIdIndex + 1];

        if (nextProjectId != null) {
            return false;
        }

        return getters.isLastPageFetched;
    },
    isProjectInList({ getters }, currentProjectId) {
        const sortedProjectsObject = getters.sortedProjectsObject;
        const sortedProjectsIds = Object.values(sortedProjectsObject).flat();

        return sortedProjectsIds.includes(currentProjectId);
    },
    async getCorrespondingProjectId(
        { getters, dispatch, commit },
        { currentProjectId, direction }: CorrespondingProjectSettings
    ) {
        const sortedProjectsObject = getters.sortedProjectsObject;
        const sortedProjectsIds = Object.values(sortedProjectsObject).flat();
        const currentProjectIdIndex = sortedProjectsIds.indexOf(currentProjectId);
        const localStorageParameters = JSON.parse(
            LocalStorageService.get(
                // @ts-ignore
                `${getters.entityType}-${getters.userId}`
            ) as string
        );

        if (currentProjectIdIndex === -1 || !LocalStorageService.has(`${getters.entityType}-${getters.userId}`)) {
            commit('clearProjectNavigationSettings');
            return null;
        }

        const {
            correspondingProjectPage,
            correspondingProjectId,
            correspondingPageToBeFetched,
            isCorrespondingPageFetched,
            currentProjectPage,
        } = determineNextAndPreviousProjectInformation(currentProjectId, direction);

        if (correspondingProjectId != null) {
            if (currentProjectPage !== correspondingProjectPage) {
                localStorageParameters.page = correspondingProjectPage;
                saveSearchParameters(localStorageParameters);
            }

            return correspondingProjectId;
        }

        if (isCorrespondingPageFetched) {
            return null;
        }

        try {
            await fetchCorrespondingPageAndUpdateStore(correspondingPageToBeFetched);
        } catch (e) {
            return null;
        }

        return await dispatch('getCorrespondingProjectId', {
            currentProjectId,
            direction,
        });
    },
};

export default actions;
