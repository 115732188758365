
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { Button, Tooltip } from 'ant-design-vue';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';

@Component({
    name: 'ToggleDisallowWarranty',
    components: {
        Tooltip,
        Button,
    },
})
export default class ToggleDisallowWarranty extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: () => [] }) private selectedOfferItems!: TransformedTableOfferItem[];
}
