import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';

const getters: GetterTree<ILocalState, {}> = {
    colors(state) {
        return state.colors;
    },
    generalColors(state) {
        return state.generalColors;
    },
    powders(state) {
        return state.powders;
    },
    prices(state) {
        return state.prices;
    },
    surfaces(state) {
        return state.surfaces;
    },
    qualities(state) {
        return state.qualities;
    },
    glosses(state) {
        return state.glosses;
    },
    effects(state) {
        return state.effects;
    },
    charts(state) {
        return state.charts;
    },
};

export default getters;
