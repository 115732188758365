import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';

const mutations: MutationTree<ILocalState> = {
    setColors(state, colors: any[]) {
        state.colors = colors;
    },
    setGeneralColors(state, generalColors: any[]) {
        state.generalColors = generalColors;
    },
    setPowders(state, powders: any[]) {
        state.powders = powders;
    },
    setPrices(state, prices: any[]) {
        state.prices = prices;
    },
    setSurfaces(state, surfaces: any[]) {
        state.surfaces = surfaces;
    },
    setQualities(state, qualities: any[]) {
        state.qualities = qualities;
    },
    setGlosses(state, glosses: any[]) {
        state.glosses = glosses;
    },
    setEffects(state, effects: any[]) {
        state.effects = effects;
    },
    setCharts(state, charts: any[]) {
        state.charts = charts;
    },
};

export default mutations;
