
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Checkbox, Divider, Form, Input } from 'ant-design-vue';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import Offer from '@/models/Offer';
import ProjectViewSidebarAttachmentsCard from '@/components/global/sidebar/ProjectViewSidebarAttachmentsCard.vue';
import OfferNote from '@/models/OfferNote';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'CustomOrderForm',
    components: {
        Form,
        FormItem: Form.Item,
        Checkbox,
        Divider,
        Textarea: Input.TextArea,
        Button,
        TwoRowWithTag,
        ProjectViewSidebarAttachmentsCard,
    },
})
export default class CustomOrderForm extends Vue {
    @Prop({ required: true }) private project!: any;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ required: true }) private lockEditing!: boolean;

    private orderDescription = '';

    public async onSubmit(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        if (this.selectedOffer == null) {
            throw new Error('A selected offer should exist');
        }

        this.$emit('update:isLoading', true);

        try {
            const currentNote = this.selectedOffer.offerNote[0];
            let noteId = null;

            if (currentNote) {
                await OfferNote.updateExisting({
                    id: currentNote.id,
                    note: this.orderDescription,
                    offerId: this.selectedOffer.id,
                });

                noteId = currentNote.id;
            } else {
                const note = await OfferNote.createNew({
                    note: this.orderDescription,
                    offerId: this.selectedOffer.id,
                });
                noteId = note?.data?.id;
            }

            await Offer.updateCustomOrderNote(this.selectedOffer.id, noteId);
            EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
        } catch (e) {
            return Promise.reject(e);
        } finally {
            this.$emit('update:isLoading', false);
        }

        return Promise.resolve();
    }

    private mounted() {
        if (this.selectedOffer?.offerNote?.length) {
            this.orderDescription = this.selectedOffer.offerNote[0].note;
        }
    }
}
