
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { RouteNames } from '@/enums/routes/RouteNames';
import { Action, Getter, Mutation } from 'vuex-class';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import {
    extractDefaultValues,
    extractUpdateFunctions,
    filterAndSortActiveItemRows,
    cssMultipositionRowElementPositioning,
} from '@/helpers/FormDataHelper';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import ConfiguratorService from '@/services/ConfiguratorService';
import ProductSlotRow from '@/components/global/popup/ProductSlotRow.vue';
import { UpdateProductFormEntry } from '@/interfaces/components/configurator/UpdateProductFormEntry';
import AddOfferItems from '@/components/global/popup/AddOfferItems.vue';
import OfferItemRepository from '@/repositories/OfferItemRepository';
import MultipositionHeader from '@/components/global/popup/MultipositionHeader.vue';
import { ProductFormConfig } from '@/interfaces/components/configurator/ProductFormConfig';
import { MultipositionDeleteMap } from '@/interfaces/general/MultipositionDeleteMap';
import OfferItem from '@/models/OfferItem';
import { removeOfferItemFromArrayById } from '@/helpers/Products/ProductHelper';
import { ProductAndProductFormId } from '@/interfaces/general/ProductAndProductFormId';
import SegmentedMultiposition from '@/components/views/project/SegmentedMultiposition.vue';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import PopupNotifications from '@/components/global/popup/PopupNotifications.vue';
import { PopupNotificationOptions } from '@/interfaces/components/projectNew/PopupNotificationOptions';
import { checkForErrorsInFieldsAndReturnState, resetHelperState } from '@/helpers/FormValidationHelper';
import { FormValidationPayload } from '@/interfaces/general/FormValidationPayload.js';
import { extractInvalidDefaultValues } from '@/helpers/FieldFunctionHelper';
import { defaultValueWarnings as defaultValueWarningsConfig } from '@/interfaces/components/projectNew/DefaultValueWarnings';
import DefaultValueWarnings from '@/components/global/popup/DefaultValueWarnings.vue';

@Component({
    name: 'EditMultipositionSlot',
    components: {
        MultipositionHeader,
        AddOfferItems,
        ProductSlotRow,
        SegmentedMultiposition,
        PopupNotifications,
        DefaultValueWarnings,
    },
})
export default class EditMultipositionSlot extends Vue {
    @Prop({ required: true }) private popupData!: any;
    @Action('configurator/getSingleProductForm') private getSingleProductForm!: (productFormId: string) => void;
    @Getter('configurator/productForm') private productForm!: (productFormId: string) => ProductForm | null;
    @Action('configurator/addActiveProductForm')
    private addActiveProductForm!: (activeProductForm: ActiveProductFormValueObject) => Promise<number | null>;
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({ pId, value, productFormId }: UpdateProductFormEntry) => void;
    @Mutation('configurator/resetActiveProductForms') private resetActiveProductForms!: () => void;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;

    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private activeItemRows: ProductRowItem[] = [];
    private activeProductFormConfigs: ProductFormConfig[] = [];
    private offerItemIdsToBeDeleted: string[] = [];
    private isAddingNewRow = false;
    private activeProductForm: ProductForm | null = null;
    private notifications: PopupNotificationOptions[][] = [];
    private flatNotifications: PopupNotificationOptions[] = [];
    private formFieldErrorsExist = false;
    private showDefaultValueWarningsModal = true;
    private savedSegmentProductIds: string[] = [];
    private isLockedSegmentStatuses: Record<number, boolean> = {};

    private get isUpdatingData() {
        return this.activeItemRows.some((activeItemRow) => activeItemRow.isUpdatingData) || this.isAddingNewRow;
    }

    private get activeProductFormIds() {
        return this.activeItemRows.map((activeItemRow: ProductRowItem) => {
            return activeItemRow.activeProductFormId;
        });
    }

    private get filteredActiveItemRows() {
        return filterAndSortActiveItemRows(
            this.popupData.productFormAndProductIds,
            this.activeItemRows,
            this.savedSegmentProductIds
        );
    }

    private get defaultValueWarnings() {
        const warnings = this.$store.getters['configurator/defaultValueWarnings'];

        return warnings;
    }

    private updateLockedSegmentStatus(isLocked: boolean, index: number) {
        this.isLockedSegmentStatuses[index] = isLocked;
    }

    private productSlotRowsReferences() {
        const segmentedMultipositions = this.$refs.segmentedMultiposition as SegmentedMultiposition[];
        const productSlotRows = segmentedMultipositions
            .map((segmentedMultiposition) => segmentedMultiposition.$refs.productSlotRow)
            .flat() as ProductSlotRow[];

        return productSlotRows;
    }

    private async getCurrentProductForm(productFormId: string): Promise<ProductForm | null> {
        if (this.productForm(productFormId) != null) {
            return this.productForm(productFormId);
        }
        try {
            await this.getSingleProductForm(productFormId);
        } catch (e) {
            this.loadingOverlay.stop();
            this.addNotification(this.$t('Dogodila se greška') as string, (e as Error).message, 'error');
            this.$router.push({ name: RouteNames.error });
            return null;
        }

        return this.productForm(productFormId);
    }

    private async addNewRow(offerItemId: string) {
        this.isAddingNewRow = true;
        this.notifications.push([]);

        let newItemRows: ProductRowItem[] = [];
        const offerItem = await OfferItemRepository.getById(offerItemId);
        if (offerItem == null) {
            return Promise.reject(new Error('Offer item not found'));
        }
        const currentActiveProductFormConfig = this.activeProductFormConfigs.find(
            (activeProductFormConfig: ProductFormConfig) => {
                const activeProductFormId = activeProductFormConfig.productFormId;
                const offerItemProductFormId = offerItem.offerItemProductSystem.product.productForm.id;

                return activeProductFormId === offerItemProductFormId;
            }
        );
        if (currentActiveProductFormConfig === undefined) {
            return Promise.reject(new Error('Active product form not found'));
        }
        try {
            newItemRows = await ConfiguratorService.addNewRow(
                currentActiveProductFormConfig.initialProductFormValues,
                currentActiveProductFormConfig.defaultUpdateFunctions,
                currentActiveProductFormConfig.defaultEditableFields,
                currentActiveProductFormConfig.availableSegments,
                this.addActiveProductForm,
                offerItem,
                true,
                1,
                null,
                offerItem.offerItemFormData,
                this.popupData.clientId,
                this.activeProductForm ? this.activeProductForm.isCustom : false
            );
        } catch (e) {
            this.addNotification(this.$t('Dogodila se greška') as string, (e as Error).message, 'error');

            this.isAddingNewRow = false;
            return Promise.reject(e);
        }
        this.activeItemRows.push(...newItemRows);
        this.isAddingNewRow = false;
        return Promise.resolve();
    }

    private updateItemRowsIsUpdatingState(activeProductFormId: number, newState: boolean) {
        const itemRowIndex = this.findRowIndexByProductFormIdInActiveItemRows(activeProductFormId);
        this.activeItemRows[itemRowIndex].isUpdatingData = newState;
    }

    private async createNewItemRow(productRowItem: ProductRowItem, existingProductValues?: ChecklistFieldEntry[]) {
        let newItemRows: ProductRowItem[] = [];
        const productFormId = productRowItem.productFormId;
        const activeProductForm = this.productForm(productFormId as string);
        const productSystemName = productRowItem.offerItem?.productSystem?.code;
        this.notifications.push([]);

        this.loadingOverlay.start();

        const initialProductFormValues = await extractDefaultValues({
            productForm: activeProductForm as ProductForm,
            productId: productRowItem.productId as string,
            clientId: this.popupData.clientId,
            productSystemName: this.popupData.productSystemName ?? productSystemName,
        });

        const defaultUpdateFunctions = extractUpdateFunctions(activeProductForm as ProductForm);
        const defaultEditableFields = ConfiguratorService.extractEditableFields(activeProductForm as ProductForm);
        const availableSegments = ConfiguratorService.getSegmentNames(activeProductForm);
        try {
            newItemRows = await ConfiguratorService.addNewRow(
                initialProductFormValues,
                defaultUpdateFunctions,
                defaultEditableFields,
                availableSegments,
                this.addActiveProductForm,
                productRowItem.offerItem as OfferItem,
                false,
                1,
                null,
                existingProductValues ? existingProductValues : undefined,
                this.popupData.clientId,
                this.activeProductForm ? this.activeProductForm.isCustom : false
            );
        } catch (e) {
            this.addNotification(this.$t('Dogodila se greška') as string, (e as Error).message, 'error');

            return Promise.reject(e);
        }

        this.activeItemRows.push(...newItemRows);
        this.loadingOverlay.stop();

        this.verifyAndFixNewRowPositioning(newItemRows[0].activeProductFormId);
        return Promise.resolve();
    }

    private async verifyAndFixNewRowPositioning(activeProductFormId: number) {
        await this.$nextTick();
        const rowElement = document.getElementById(String(activeProductFormId)) as any;

        cssMultipositionRowElementPositioning(rowElement);
    }

    private deleteExistingRow({ offerItemId, activeProductFormId }: MultipositionDeleteMap) {
        if (this.activeItemRows.length === 1) {
            this.addNotification(
                this.$t('Dogodila se greška') as string,
                this.$t('Nije moguće obrisati zadnji redak.') as string,
                'error'
            );
            return;
        }

        this.markOfferItemToBeDeleted({
            offerItemId,
            activeProductFormId,
        });
    }

    private markOfferItemToBeDeleted({ offerItemId, activeProductFormId }: MultipositionDeleteMap) {
        const rowIndexToBeDeleted = this.findRowIndexByProductFormIdInActiveItemRows(activeProductFormId);

        if (rowIndexToBeDeleted === -1) {
            return;
        }

        this.activeItemRows.splice(rowIndexToBeDeleted, 1);

        if (offerItemId != null) {
            this.offerItemIdsToBeDeleted.push(offerItemId);
        }

        this.updateOfferItemIdsForUpdatingRowNumbers(offerItemId);
        this.notifications.splice(rowIndexToBeDeleted, 1);
        this.flatNotifications = this.notifications.flat();
    }

    private updateOfferItemIdsForUpdatingRowNumbers(offerItemId: string | null) {
        if (offerItemId == null) {
            return;
        }

        this.popupData.offerItemIdsForUpdatingRowNumbers = removeOfferItemFromArrayById(
            offerItemId,
            this.popupData.offerItemIdsForUpdatingRowNumbers
        );
    }

    private findRowIndexByProductFormIdInActiveItemRows(activeProductFormId: number) {
        return this.activeItemRows.findIndex((itemRow: ProductRowItem) => {
            return itemRow.activeProductFormId === activeProductFormId;
        });
    }

    private getRowsCurrentProductForm(productFormId: number | null) {
        if (productFormId == null) {
            return null;
        }
        return this.productForm(String(productFormId));
    }

    private async setDefaultProductFormSettings(productFormAndProductId: ProductAndProductFormId) {
        try {
            this.activeProductForm = await this.getCurrentProductForm(productFormAndProductId.productFormId);
        } catch (e) {
            return;
        }
        if (this.activeProductForm == null) {
            return Promise.reject(new Error('Greška pri dohvaćanju proizvoda'));
        }

        const defaultUpdateFunctions = extractUpdateFunctions(this.activeProductForm);
        // tslint:disable-next-line:max-line-length
        const defaultEditableFields = ConfiguratorService.extractEditableFields(this.activeProductForm);
        const initialProductFormValues = await extractDefaultValues({
            productForm: this.activeProductForm as ProductForm,
            productId: productFormAndProductId.productId,
            clientId: this.popupData.clientId,
            productSystemName: this.popupData.productSystemName,
        });
        const availableSegments = ConfiguratorService.getSegmentNames(this.activeProductForm);

        this.activeProductFormConfigs.push({
            activeProductForm: this.activeProductForm,
            defaultUpdateFunctions,
            defaultEditableFields,
            productFormId: productFormAndProductId.productFormId,
            availableSegments,
            initialProductFormValues,
        });
        return Promise.resolve();
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup, true);
    }

    private addNotification(title: string, description: string, errorType: 'error' | 'warning', index = 0) {
        this.notifications[index].push({
            title: this.$t(title),
            description: this.$t(description),
            errorType,
            index: index + 1,
        });
        this.flatNotifications = this.notifications.flat();
    }

    private onShowNotification(event: {
        index: number;
        title: string;
        description: string;
        type: 'error' | 'warning';
    }) {
        this.addNotification(event.title, event.description, event.type, event.index);
    }

    private onClearNotifiactions(index: number) {
        this.notifications[index] = [];
        this.flatNotifications = this.notifications.flat();
    }

    private async validateFormFields(activeRows = this.activeItemRows) {
        this.formFieldErrorsExist = false;
        // Get visible fields and validate their values
        const formValues: FormValidationPayload[] = [];

        for (const itemRow of activeRows) {
            const rowValues = this.getProductFormValues(itemRow.activeProductFormId);

            formValues.push({
                rowKey: itemRow.activeProductFormId,
                values: { ...rowValues },
                fieldSegments: itemRow.editableFields as any,
            });
        }

        const errorState = checkForErrorsInFieldsAndReturnState(formValues);

        const hasErrors = errorState.hasErrors;
        this.formFieldErrorsExist = hasErrors;

        if (hasErrors) {
            this.showErrorState(errorState.errorDescription);
        }

        EventBus.$emit(EventBusEvents.formValidationFinished);
    }

    private showErrorState(description: string) {
        this.$notification.error({
            message: this.$t('Invalid characters detected in form fields:') as string,
            description: description as string,
        });
    }

    private async determineInvalidDefaultValues() {
        const invalidDefaultValues: defaultValueWarningsConfig[] = [];

        this.activeItemRows.forEach((itemRow: any) => {
            const activeRowFields = itemRow.editableFields;
            const currentProductFormValues = this.getProductFormValues(itemRow.activeProductFormId);

            const defaultValueWarnings: Array<any> = extractInvalidDefaultValues(
                activeRowFields,
                currentProductFormValues,
                itemRow.activeProductFormId
            );
            invalidDefaultValues.push({
                id: itemRow?.offerItem?.displayRowNumber ?? itemRow.activeProductFormId,
                productName: itemRow.productName,
                productId: itemRow.productId,
                productFormId: itemRow.activeProductFormId,
                warnings: defaultValueWarnings,
            });
        });

        await this.$store.dispatch('configurator/setDefaultValueWarnings', invalidDefaultValues);

        return Promise.resolve();
    }

    private async removeSegmentFromMultiposition(productFormId: string) {
        this.savedSegmentProductIds.push(productFormId);
    }

    private async created() {
        resetHelperState();
        this.loadingOverlay.start();

        for await (const productFormAndProductId of this.popupData.productFormAndProductIds) {
            try {
                await this.setDefaultProductFormSettings(productFormAndProductId);
            } catch (e) {
                this.addNotification(this.$t('Dogodila se greška') as string, (e as Error).message, 'error');

                this.loadingOverlay.stop();
                return;
            }
        }

        await Promise.all(
            this.popupData.offerItemIds.map((offerItemId: string) => {
                return this.addNewRow(offerItemId);
            })
        );

        await this.$nextTick();
        this.determineInvalidDefaultValues();

        this.loadingOverlay.stop();

        EventBus.$on(EventBusEvents.showNotification, this.onShowNotification);
        EventBus.$on(EventBusEvents.clearNotifications, this.onClearNotifiactions);
        EventBus.$on(EventBusEvents.removeSavedMultipositionSegment, this.removeSegmentFromMultiposition);
    }

    private closeModal() {
        this.showDefaultValueWarningsModal = false;
    }

    @Watch('filteredActiveItemRows')
    private closeMultiposition() {
        if (this.filteredActiveItemRows.length) {
            return;
        }

        EventBus.$emit(EventBusEvents.closePopup);
    }

    private beforeDestroy() {
        this.notifications = [];
        this.flatNotifications = [];
        this.resetActiveProductForms();
        // Remove warnings for invalid default values
        this.$store.dispatch('configurator/setDefaultValueWarnings', []);
        setTimeout(() => {
            this.$notification.destroy();
        }, 2000);
        EventBus.$off(EventBusEvents.showNotification, this.onShowNotification);
        EventBus.$off(EventBusEvents.clearNotifications, this.onClearNotifiactions);
        EventBus.$off(EventBusEvents.removeSavedMultipositionSegment, this.removeSegmentFromMultiposition);
    }
}
