
import { Component, Vue } from 'vue-property-decorator';
import ColorModuleHeader from '@/components/global/color-module/ColorModuleHeader.vue';
import ColorModuleTable from '@/components/global/color-module/ColorModuleTable.vue';
import ColorModuleRequest from '@/components/global/color-module/ColorModuleRequest.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Color from '@/models/Color';
import { ColorData } from '@/interfaces/components/color/ColorData';
import { formatCustomColor, formatColorSearchQuery } from '@/helpers/ColorModule/ColorModuleHelper';
import { ColorRequestConfig } from '@/interfaces/components/color/ColorRequestConfig';
import { ColorFilters } from '@/interfaces/components/color/ColorFilters';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ColorModule',
    components: {
        ColorModuleHeader,
        ColorModuleTable,
        ColorModuleRequest,
    },
})
export default class ColorModule extends Vue {
    private visible: boolean = false;
    private infoModalVisible: boolean = false;
    private checklistFieldPID: string = '';
    private fieldValue: string = '';
    private activeProductFormId: number = 0;
    private activeTab: string = 'color-search';
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private filters: ColorFilters = {
        colorGloss: { id: '', value: '' },
        colorSurface: { id: '', value: '' },
        colorQuality: { id: '', value: '' },
        colorEffect: { id: '', value: '' },
        colorSystem: { id: '', value: '' },
        colorSystemName: { id: '', value: '' },
    };
    private requestForm: ColorRequestConfig = {
        colorName: '',
        colorProducer: '',
        powderID: '',
    };
    private searchQuery: string = '';
    private filtersActive: boolean = false;
    private moduleLoaded = false;

    // $t('Color representations are based on RGB values and may differ from the original')
    private get requestColor() {
        return formatCustomColor(this.requestForm, this.filters);
    }

    private async openPopup(payload?: { checklistFieldPID: string; fieldValue: string; activeProductFormId: number }) {
        if (payload) {
            this.checklistFieldPID = payload.checklistFieldPID;
            this.fieldValue = payload.fieldValue;
            this.activeProductFormId = payload.activeProductFormId;
        }

        this.visible = true;

        if (!this.moduleLoaded) {
            this.moduleLoaded = true;
            this.infoModalVisible = true;
            this.fetchAndRegisterColorsData();
        }
    }

    private closePopup() {
        this.visible = false;
        // reset forms
        this.requestForm = {
            colorName: '',
            colorProducer: '',
            powderID: '',
        };
        this.filters = {
            colorGloss: { id: '', value: '' },
            colorSurface: { id: '', value: '' },
            colorQuality: { id: '', value: '' },
            colorEffect: { id: '', value: '' },
            colorSystem: { id: '', value: '' },
            colorSystemName: { id: '', value: '' },
        };
    }

    private changeActiveTab(tab: string) {
        this.activeTab = tab;
    }

    private setFiltersActiveState(active: boolean) {
        this.filtersActive = active;
    }

    private selectColor(formattedColor: any) {
        this.sendCustomColorRequest(formattedColor);
    }

    private sendCustomColorRequest(selectedColor: string = '') {
        const payload = {
            activeProductFormId: this.activeProductFormId,
            fieldToBeUpdatedPID: this.checklistFieldPID,
            fieldValue: this.fieldValue,
            shouldFocusManualEntryField: true,
            manualEntryFieldValue: selectedColor || this.requestColor,
        };

        if (this.activeProductFormId === null) {
            EventBus.$emit(EventBusEvents.updateMultipositionHeaderSystemColorValue, payload);
        } else {
            EventBus.$emit(EventBusEvents.updateSystemColorValue, payload);
        }

        this.closePopup();
    }

    private onFilterChange({ value, type, id }: { value: string; type: keyof ColorFilters; id: string }) {
        this.filters[type] = { id, value };

        if (type === 'colorSystem') {
            this.filters.colorSystemName = { id: '', value: '' };
            return;
        }

        if (this.activeTab === 'color-search') {
            this.fetchColors(this.searchQuery);
        }
    }

    private searchColors({ query, shouldFetch }: { query: string; shouldFetch: boolean }) {
        this.searchQuery = query;

        if (shouldFetch) {
            this.fetchColors(query);
        }
    }

    private async fetchColors(searchQuery?: string) {
        try {
            this.loadingOverlay.start();

            const query = formatColorSearchQuery(this.filters, searchQuery);

            const colors = await Color.getAll(query);
            this.$store.dispatch('colorData/setColorsData', { colors });
        } catch (error) {
            console.error(error);
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private async fetchAndRegisterColorsData() {
        const requests: Promise<ColorData>[] = [
            Color.getAll(),
            Color.getGeneralColors(),
            Color.getPowders(),
            Color.getPowderPrices(),
            Color.getSurfaces(),
            Color.getQualities(),
            Color.getGlosses(),
            Color.getEffects(),
            Color.getColorCharts(),
        ];

        try {
            this.loadingOverlay.start();

            const results = await Promise.allSettled(requests);
            const [colors, generalColors, powders, prices, surfaces, qualities, glosses, effects, charts] = results.map(
                (result) => (result.status === 'fulfilled' ? result.value : [])
            );

            this.$store.dispatch('colorData/setColorsData', {
                colors,
                generalColors,
                powders,
                prices,
                surfaces,
                qualities,
                glosses,
                effects,
                charts,
            });
        } catch (error) {
            console.error(error);
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private async mounted() {
        EventBus.$on(EventBusEvents.showColorModule, this.openPopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.showColorModule, this.openPopup);
    }
}
