
import { Component, Vue, Prop } from 'vue-property-decorator';
import ColorModuleSearch from '@/components/global/color-module/ColorModuleSearch.vue';

@Component({
    name: 'ColorModuleHeader',
    components: {
        ColorModuleSearch,
    },
})
export default class ColorModuleHeader extends Vue {
    @Prop({ required: true }) private requestResult!: string;
    @Prop({ required: true }) private value!: any;

    private handleSubmit() {
        this.$emit('sendRequest');
    }
}
