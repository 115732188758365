import User from '@/models/User';

export class UserRepository {
    public static getCurrentUser() {
        return User.query().withAllRecursive().first();
    }

    public static getUserById(id: string | null | undefined) {
        if (id == null) {
            return null;
        }

        return User.query().whereId(id).withAll().first();
    }

    public static getCurrentUsersCurrencySymbol() {
        const currentUser = User.query().withAllRecursive().first();

        if (currentUser == null || currentUser.currency == null) {
            return '€';
        }

        return currentUser.currency.symbol;
    }

    public static getCurrentUsersUserGroups() {
        const currentUser = User.query().withAllRecursive().first();

        if (currentUser == null) {
            return [];
        }

        return currentUser.userGroupConnections.map((userGroupConnection) => userGroupConnection.userGroup);
    }

    public static isCurrentUserTechnologist() {
        const currentUser = User.query().withAllRecursive().first();

        if (currentUser == null) {
            return false;
        }

        return !!currentUser.isTechnologist;
    }
}
