
import { Component, Prop, Vue } from 'vue-property-decorator';
import ColorModuleSearch from '@/components/global/color-module/ColorModuleSearch.vue';
import ColorModuleFilters from '@/components/global/color-module/ColorModuleFilters.vue';

@Component({
    name: 'ColorModuleHeader',
    components: {
        ColorModuleSearch,
        ColorModuleFilters,
    },
})
export default class ColorModuleHeader extends Vue {
    @Prop({ required: false, default: false }) private activeTab!: string;
    @Prop({ required: true }) private filters!: any;
    @Prop({ required: true }) private filtersActive!: boolean;

    private get showFilters() {
        if (this.activeTab === 'color-request') {
            return true;
        }

        return this.filtersActive;
    }

    private setFilters(active: boolean) {
        this.$emit('setFiltersActiveState', active);
    }
}
