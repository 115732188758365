
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FiltersConfiguration } from '@/helpers/ColorModule/ColorModuleHelper';

@Component({
    name: 'ColorModuleFilters',
    components: {},
})
export default class ColorModuleFilters extends Vue {
    @Prop({ required: true }) private filters!: any;
    @Prop({ required: false, default: false }) private activeTab!: string;

    private get FiltersConfiguration(): any {
        return FiltersConfiguration.filter((filter) => !filter.isMandatory);
    }

    private onFilterChange(value: string, type: string, option: any) {
        this.$emit('filterChange', { value, type, id: option?.key || '' });
    }
}
