import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class ProjectLabelTemplateAPI {
    public static async getAll() {
        let projectLabelTemplates;

        try {
            projectLabelTemplates = await ApiController.get(ApiRoutes.projectLabelTemplates.get);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(projectLabelTemplates);
    }

    public static async getById(projectLabelTemplateId: string) {
        let projectLabelTemplates;

        try {
            projectLabelTemplates = await ApiController.get(
                ApiRoutes.projectLabelTemplates.getById(projectLabelTemplateId)
            );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(projectLabelTemplates);
    }

    public static async update(projectLabelTemplateId: string, projectLabelTemplateInfo: AvailableLabelFields) {
        let projectLabelTemplate;
        const request = {
            data: {
                type: 'project_label_templates',
                id: projectLabelTemplateId,
                attributes: {
                    name: projectLabelTemplateInfo.name,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: projectLabelTemplateInfo.locale.id,
                        },
                    },
                },
            },
        };

        try {
            projectLabelTemplate = await ApiController.patch(
                ApiRoutes.projectLabelTemplates.update(projectLabelTemplateId),
                request
            );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(projectLabelTemplate);
    }

    public static async create(projectLabelTemplateInfo: AvailableLabelFields) {
        let projectLabelTemplate;
        const request = {
            data: {
                type: 'project_label_templates',
                attributes: {
                    name: projectLabelTemplateInfo.name,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: projectLabelTemplateInfo.locale.id,
                        },
                    },
                },
            },
        };

        try {
            projectLabelTemplate = await ApiController.post(ApiRoutes.projectLabelTemplates.create, request);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(projectLabelTemplate);
    }

    public static async delete(projectLabelTemplateId: string) {
        try {
            await ApiController.delete(ApiRoutes.projectLabelTemplates.delete(projectLabelTemplateId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
