
import { Component, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';

@Component({
    name: 'ProjectViewSidebarCustomOrderSwitch',
    components: {
        SidebarCard,
    },
})
export default class ProjectViewSidebarCustomOrderSwitch extends Vue {
    private createCustomOrder: boolean = false;

    private onChange() {
        this.$emit('createCustomOrder', this.createCustomOrder);
    };
}
