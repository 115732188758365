var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"color-module-request"},[_c('a-form',{staticClass:"color-module-request__form"},[_c('a-form-item',{staticClass:"color-module-request__form-item",attrs:{"label":_vm.$t('Color name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'colorName',
                    {
                        rules: [{ required: true, message: _vm.$t('Color name is required!') }],
                    },
                ]),expression:"[\n                    'colorName',\n                    {\n                        rules: [{ required: true, message: $t('Color name is required!') }],\n                    },\n                ]"}],model:{value:(_vm.value.colorName),callback:function ($$v) {_vm.$set(_vm.value, "colorName", $$v)},expression:"value.colorName"}})],1),_c('a-form-item',{staticClass:"color-module-request__form-item",attrs:{"label":_vm.$t('Color producer')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'colorProducer',
                    {
                        rules: [{ required: true, message: _vm.$t('Color producer is required!') }],
                    },
                ]),expression:"[\n                    'colorProducer',\n                    {\n                        rules: [{ required: true, message: $t('Color producer is required!') }],\n                    },\n                ]"}],model:{value:(_vm.value.colorProducer),callback:function ($$v) {_vm.$set(_vm.value, "colorProducer", $$v)},expression:"value.colorProducer"}})],1),_c('a-form-item',{staticClass:"color-module-request__form-item",attrs:{"label":_vm.$t('Powder ID')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'powderID',
                    {
                        rules: [{ required: true, message: _vm.$t('Powder ID is required!') }],
                    },
                ]),expression:"[\n                    'powderID',\n                    {\n                        rules: [{ required: true, message: $t('Powder ID is required!') }],\n                    },\n                ]"}],model:{value:(_vm.value.powderID),callback:function ($$v) {_vm.$set(_vm.value, "powderID", $$v)},expression:"value.powderID"}})],1)],1),_c('div',{staticClass:"color-module-request__result"},[_c('span',{staticClass:"color-module-request__result-label"},[_vm._v(_vm._s(_vm.$t('Requested color: ')))]),_c('div',{staticClass:"color-module-request__result-wrap"},[_c('a-input',{attrs:{"data-test":"color-picker-result__result-text","read-only":true,"value":_vm.requestResult}}),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('Save request'))+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }