import i18n from '@/i18n';
// @ts-ignore
import localeSl from 'ant-design-vue/es/date-picker/locale/sl_SI';
// @ts-ignore
import localeIt from 'ant-design-vue/es/date-picker/locale/it_IT';
// @ts-ignore
import localeDe from 'ant-design-vue/es/date-picker/locale/de_DE';
// @ts-ignore
import localeEn from 'ant-design-vue/es/date-picker/locale/en_US';
// @ts-ignore
import localeHu from 'ant-design-vue/es/date-picker/locale/hu_HU';
// @ts-ignore
import hr_HR from 'ant-design-vue/es/locale-provider/hr_HR';
// @ts-ignore
import sl_SI from 'ant-design-vue/es/locale-provider/sl_SI';
// @ts-ignore
import de_DE from 'ant-design-vue/es/locale-provider/de_DE';
// @ts-ignore
import it_IT from 'ant-design-vue/es/locale-provider/it_IT';
// @ts-ignore
import en_US from 'ant-design-vue/es/locale-provider/en_US';
// @ts-ignore
import hu_HU from 'ant-design-vue/es/locale-provider/hu_HU';
import moment from 'moment';

export function getDatepickerLocale() {
    let localeStrings = {
        lang: {
            placeholder: 'Odaberite datum',
            rangePlaceholder: ['Početni datum', 'Završni datum'],
            today: 'Danas',
            now: 'Sad',
            backToToday: 'Natrag na danas',
            ok: 'Ok',
            clear: 'Očisti',
            month: 'Mjesec',
            year: 'Godina',
            timeSelect: 'odaberite vrijeme',
            dateSelect: 'odaberite datum',
            weekSelect: 'Odaberite tjedan',
            monthSelect: 'Odaberite mjesec',
            yearSelect: 'Odaberite godinu',
            decadeSelect: 'Odaberite desetljeće',
            yearFormat: 'YYYY',
            dateFormat: 'D.M.YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'D.M.YYYY HH:mm',
            monthBeforeYear: true,
            previousMonth: 'Prošli mjesec (PageUp)',
            nextMonth: 'Sljedeći mjesec (PageDown)',
            previousYear: 'Prošla godina (Control + left)',
            nextYear: 'Sljedeća godina (Control + right)',
            previousDecade: 'Prošlo desetljeće',
            nextDecade: 'Sljedeće desetljeće',
            previousCentury: 'Prošlo stoljeće',
            nextCentury: 'Sljedeće stoljeće',
        },
        timePickerLocale: {
            placeholder: 'Odaberite vrijeme',
        },
    };
    switch (i18n.locale) {
        case 'sl':
            localeStrings = localeSl;
            break;
        case 'it':
            localeStrings = localeIt;
            break;
        case 'de':
            localeStrings = localeDe;
            break;
        case 'en':
            localeStrings = localeEn;
            break;
        case 'hu':
            localeStrings = localeHu;
            break;
    }

    localeStrings.lang.dateTimeFormat = 'DD.MM.YYYY HH:mm';
    localeStrings.lang.dateFormat = 'DD.MM.YYYY';

    return localeStrings;
}

export function getTableLocale() {
    switch (i18n.locale) {
        case 'sl':
            return sl_SI;
        case 'it':
            return it_IT;
        case 'de':
            return de_DE;
        case 'hr':
            return hr_HR;
        case 'en':
            return en_US;
        case 'hu':
            return hu_HU;
    }
}

export function formatDateToISOString(date: any) {
    if (!date || date === 'null') {
        return '';
    }

    if (typeof date === 'string') {
        return new Date(date).toISOString();
    }

    return date.toISOString(date);
}

export function formatDateToDateString(date: any) {
    if (!date || date === 'null') {
        return '';
    }

    if (typeof date === 'string') {
        return moment(date).format('YYYY-MM-DD');
    }

    return date.format('YYYY-MM-DD');
}
