import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class LabelAPI {
    public static async getAll() {
        let labels;

        try {
            labels = (await ApiController.get(ApiRoutes.labels.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(labels);
    }

    public static async getAllFromAllUserGroups() {
        let labels;

        try {
            labels = (await ApiController.get(ApiRoutes.labels.getAll)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(labels);
    }

    public static async getByProjectState(projectState: string) {
        let labels;

        try {
            labels = (await ApiController.get(ApiRoutes.labels.getByProjectState(projectState))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(labels);
    }

    public static async getById(labelId: string) {
        let labels;

        try {
            labels = (await ApiController.get(ApiRoutes.labels.getById(labelId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(labels);
    }

    public static async update(labelId: string, labelInfo: AvailableLabelFields) {
        let label;
        const request = {
            data: {
                type: 'project_labels',
                id: labelId,
                attributes: {
                    name: labelInfo.name,
                    isDefault: labelInfo.isDefault,
                    color: labelInfo.color?.hex || labelInfo.color,
                    projectState: labelInfo.projectState,
                },
            },
        };

        try {
            label = (await ApiController.patch(ApiRoutes.labels.update(labelId), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(label);
    }

    public static async create(labelInfo: AvailableLabelFields) {
        let label;
        const request = {
            data: {
                type: 'project_labels',
                attributes: {
                    name: labelInfo.name,
                    isDefault: labelInfo.isDefault,
                    color: labelInfo.color?.hex || labelInfo.color,
                    projectState: labelInfo['Project state']?.name,
                },
            },
        };

        try {
            label = (await ApiController.post(ApiRoutes.labels.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(label);
    }

    public static async delete(labelId: string) {
        try {
            await ApiController.delete(ApiRoutes.labels.delete(labelId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async fetchAvailableLabels(projectId: string) {
        let availableLabels;

        try {
            availableLabels = await ApiController.get(ApiRoutes.labels.fetchAvailableLabels(projectId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(availableLabels);
    }
}
