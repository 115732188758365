
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DeleteIcon from '@/icons/DeleteIcon.vue';
import ArrowInformationIcon from '@/icons/ArrowInformationIcon.vue';
import ExpandAllIcon from '@/components/icons/ExpandAllIcon.vue';
import EditIcon from '@/icons/EditIcon.vue';
import DuplicateIcon from '@/icons/DuplicateIcon.vue';
import { getAppropriateColumnSettings } from '@/helpers/OfferItemTableHelper';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import { UserRepository } from '@/repositories/UserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';
import OfferItemPriceManipulation from '@/models/OfferItemPriceManipulation';
import Offer from '@/models/Offer';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'OfferItemsTable',
    components: {
        DataTable,
        Column,
        DeleteIcon,
        EditIcon,
        DuplicateIcon,
        ArrowInformationIcon,
        ExpandAllIcon,
    },
})
export default class OfferItemsTable extends Vue {
    public selectedOfferItems = [];
    @Prop({ default: false }) private canUserViewPurchasePrice!: boolean;
    @Prop({ required: true }) private dataSource!: TransformedTableOfferItem[];
    @Prop({ required: true }) private onEdit!: (offerItemId: number) => void;
    @Prop({ required: true }) private isEditMode!: boolean;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ required: true }) private shouldAllowMoveAndReorder!: boolean;
    @Prop({ default: false }) private isNewProject!: boolean;
    @Prop({ required: false }) private isCurrentUserTechnologist!: boolean;

    private columns: any = {};
    private expandedRows: any[] = [];
    private sortOrder = null;
    private sortField = null;
    private componentKey = 0;
    private rows: any[] = [];
    private expandedAll: boolean = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get canAllowErrors() {
        return this.userRights.includes(UserRightsEnum.ALLOW_OFFER_ERRORS);
    }

    public onResetOfferItemsState() {
        this.rows = this.dataSource;
    }

    private updatedSize() {
        EventBus.$emit('resize');
    }

    private mounted() {
        window.addEventListener('resize', this.updatedSize);

        this.updatedSize();
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.updatedSize);
    }

    private mergePriceDetails(offerItem: TransformedTableOfferItem): any[] {
        const result: any[] = [];
        if (!offerItem) {
            return result;
        }

        // Define the regular expression pattern to find if the pricelist name has discount in it
        const pattern = /-\d{1,2}%$/;

        // first set all selling prices, if matching purchase price exists set it as well
        if (offerItem.sellingPriceDetails !== null && offerItem.sellingPriceDetails.length > 0) {
            for (let i = 0; i < offerItem.sellingPriceDetails.length; i++) {
                const sellingPriceDetails = offerItem.sellingPriceDetails[i];
                if (sellingPriceDetails) {
                    // define selling prices
                    let pricelistName = sellingPriceDetails.priceDetailName;
                    let discountName = '-0%';

                    if (pattern.test(sellingPriceDetails.priceDetailName)) {
                        discountName = sellingPriceDetails.priceDetailName.match(pattern)[0];
                        pricelistName = pricelistName.replace(discountName, '').trim();
                    }
                    let index = result.findIndex((item) => item.priceDetailName === pricelistName);

                    if (index === -1) {
                        result.push({
                            priceDetailName: sellingPriceDetails.priceDetailName,
                            quantity: sellingPriceDetails.quantity,
                            originalPrice: sellingPriceDetails.pricePerItem,
                            purchaseDiscount: '-0%',
                            purchasePrice: sellingPriceDetails.pricePerItem,
                            sellingDiscount: '-0%',
                            sellingPrice: sellingPriceDetails.pricePerItem,
                            originalTotalPrice: sellingPriceDetails.priceDetailAmount,
                            purchaseTotalPrice: sellingPriceDetails.priceDetailAmount,
                            sellingTotalPrice: sellingPriceDetails.priceDetailAmount,
                        });
                    } else {
                        result[index].sellingDiscount = discountName;
                        result[index].sellingPrice = result[index].sellingPrice + sellingPriceDetails.pricePerItem;
                        result[index].sellingTotalPrice =
                            result[index].sellingTotalPrice + sellingPriceDetails.priceDetailAmount;
                    }
                }
            }
            if (this.canUserViewPurchasePrice && offerItem.purchasePriceDetails) {
                for (let i = 0; i < offerItem.purchasePriceDetails.length; i++) {
                    const purchasePriceDetails = offerItem.purchasePriceDetails[i];
                    if (pattern.test(purchasePriceDetails.priceDetailName)) {
                        // define purchase prices
                        const discountName = purchasePriceDetails.priceDetailName.match(pattern)[0];
                        const pricelistName = purchasePriceDetails.priceDetailName.replace(discountName, '').trim();
                        const index = result.findIndex((item) => item.priceDetailName === pricelistName);

                        if (index === -1) {
                            result.push({
                                priceDetailName: purchasePriceDetails.priceDetailName,
                                quantity: purchasePriceDetails.quantity,
                                originalPrice: purchasePriceDetails.pricePerItem,
                                purchaseDiscount: 0,
                                purchasePrice: purchasePriceDetails.pricePerItem,
                                sellingDiscount: 0,
                                sellingPrice: purchasePriceDetails.pricePerItem,
                                originalTotalPrice: purchasePriceDetails.priceDetailAmount,
                                purchaseTotalPrice: purchasePriceDetails.priceDetailAmount,
                                sellingTotalPrice: purchasePriceDetails.priceDetailAmount,
                            });
                        } else {
                            result[index].purchaseDiscount = discountName;
                            result[index].purchasePrice =
                                result[index].purchasePrice + purchasePriceDetails.pricePerItem;
                            result[index].purchaseTotalPrice =
                                result[index].purchaseTotalPrice + purchasePriceDetails.priceDetailAmount;
                        }
                    }
                }
            }
        } else {
            // if there are no price details use total price fields
            if (this.canUserViewPurchasePrice && offerItem.finalPurchasePrice) {
                result.push({
                    purchaseTotalPrice: offerItem.finalPurchasePrice,
                    sellingTotalPrice: offerItem.finalPurchasePrice,
                });
            }

            if (offerItem.finalSellingPrice) {
                result.push({
                    purchaseTotalPrice: offerItem.finalSellingPrice,
                    sellingTotalPrice: offerItem.finalSellingPrice,
                });
            }
        }

        return result;
    }

    private rowClass(row: TransformedTableOfferItem) {
        if (!this.selectedOffer) {
            return '';
        }

        if (row.hasErrors && !row.allowErrors) {
            return 'has-errors';
        }

        if (row.hasErrors && row.allowErrors && !row.validWarranty) {
            return 'has-warnings';
        }

        if (!row.hasErrors && !row.validWarranty) {
            return 'has-warnings';
        }

        return '';
    }

    private onRowReorder(event: any) {
        this.rows = event.value;
        this.resetSortOptions();
    }

    private resetSortOptions() {
        this.sortField = null;
        this.sortOrder = null;
        this.selectedOfferItems = [];
        this.expandedRows = [];
    }

    private onEditOfferItemPriceManipulation(offerItemPriceManipulationId: string) {
        EventBus.$emit(EventBusEvents.onOfferItemPriceManipulation, {
            popupEvent: PopupEvents.offerItemPriceManipulation,
            data: {
                offerItemPriceManipulationId,
            },
        });
    }

    private onDeleteOfferItemPriceManipulation(offerItemPriceManipulationId: string) {
        this.$confirm({
            title: this.$t('Jeste li sigurni da želite obrisati ovu promjenu cijene?'),
            content: '',
            onOk: async () => {
                try {
                    await OfferItemPriceManipulation.deleteExisting(offerItemPriceManipulationId);
                    EventBus.$emit(EventBusEvents.updateProject);
                    EventBus.$emit(EventBusEvents.toggleEditingForProductsInOffer);
                    await Offer.getForOffersTab((this.selectedOffer as Offer).id);
                } catch (e) {
                    let error;

                    if (e instanceof Error) {
                        error = e.message;
                    } else {
                        error = (
                            e as {
                                response: {
                                    data: { meta: { message: string } };
                                };
                            }
                        ).response.data.meta.message;
                    }

                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: e.response ? e.response.data.meta.message : e.message,
                    });
                    return;
                }
                this.$notification.success({
                    message: this.$t('Promjena cijene je uspješno obrisana!') as string,
                    description: '',
                });
            },
        });
    }

    private toggleExpansionAll(): void {
        if (this.expandedAll) {
            this.expandedRows = [];
        } else {
            this.expandedRows = this.rows.slice();
        }

        this.expandedAll = !this.expandedAll;
    }

    @Watch('dataSource', { deep: true, immediate: true })
    private onDataSourceChange() {
        if (this.dataSource == null) {
            return;
        }

        this.onResetOfferItemsState();
    }

    @Watch('isEditMode', { immediate: true })
    private onEditModeChange() {
        this.resetSortOptions();
        this.componentKey += 1;

        this.columns = getAppropriateColumnSettings({
            isEditMode: this.isEditMode,
            shouldBeAbleToMoveAndReorder: this.shouldAllowMoveAndReorder,
            currencySymbol: this.currencySymbol,
            canUserViewPurchasePrice: this.canUserViewPurchasePrice,
            isNewProject: this.isNewProject,
        });
    }
}
