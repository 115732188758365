import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';
import { JwtData } from '@/interfaces/general/JwtData';
import { UserRepository } from '@/repositories/UserRepository';
import { TechnologistUserRightsEnum } from '@/enums/global/TechnologistUserRights';

const getters: GetterTree<ILocalState, {}> = {
    allowedUserGroups(state) {
        if (Object.keys(state.jwtData).length <= 0) {
            return [];
        }
        const jwtData = state.jwtData as JwtData;
        return jwtData.userGroups;
    },
    userRights(state) {
        if (Object.keys(state.jwtData).length <= 0 || state.currentUserGroup == null) {
            return [];
        }
        const jwtData = state.jwtData as JwtData;
        const currentUser = UserRepository.getCurrentUser();

        const currentUserGroupRights = Object.keys(jwtData.userRights![state.currentUserGroup]);
        const technologistRights = Object.keys(TechnologistUserRightsEnum);

        if (currentUser?.isTechnologist) {
            const filteredUserGroupRights = currentUserGroupRights.filter((right) => {
                return technologistRights.includes(right);
            });

            return filteredUserGroupRights;
        }

        return currentUserGroupRights;
    },
    userRoles(state) {
        if (Object.keys(state.jwtData).length <= 0) {
            return [];
        }

        const jwtData = state.jwtData as JwtData;
        return jwtData.roles;
    },

    userId(state) {
        if (Object.keys(state.jwtData).length <= 0) {
            return null;
        }

        const jwtData = state.jwtData as JwtData;
        return jwtData.id;
    },
    currentUserGroup(state) {
        return state.currentUserGroup;
    },

    jwtData(state) {
        return state.jwtData;
    },
    isRoltek(state) {
        const userGroup = String(state.currentUserGroup);

        if (userGroup === '1' || userGroup === '2') {
            return true;
        }

        return false;
    },
    isRoltekUserGroup(state) {
        const userGroup = String(state.currentUserGroup);

        if (userGroup === '1') {
            return true;
        }

        return false;
    },
};

export default getters;
