
import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatSelectedColorForCustomRequest } from '@/helpers/ColorModule/ColorModuleHelper';

@Component({
    name: 'ColorModuleTable',
    components: {},
})
export default class ColorModuleTable extends Vue {
    @Prop({ required: false, default: false }) private filtersActive!: string;
    @Prop({ required: false }) private searchQuery!: any;

    private get colors() {
        const colors = this.$store.getters['colorData/colors'];
        return colors;
        // if (!this.searchQuery) {
        //     return colors;
        // }

        // return colors.filter((color: any) => {
        //     return color.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        // });
    }

    removePaginationTooltips() {
        const paginationItems = document.querySelectorAll(
            '.ant-pagination-item, .ant-pagination-prev, .ant-pagination-next'
        );
        paginationItems.forEach((item) => {
            item.removeAttribute('title'); // Nuke the tooltip behavior
        });
    }

    private get columns() {
        return [
            {
                dataIndex: 'color',
                key: 'color',
                scopedSlots: { customRender: 'color' },
                slots: { title: 'colorTitle' }, // Use `slots` for title
                width: 70,
            },
            { title: this.$t('Color name'), dataIndex: 'colorName', key: 'colorName', width: 450 },
            { title: this.$t('Color Producer'), dataIndex: 'colorProducer', key: 'colorProducer', width: 270 },
            { title: this.$t('Powder ID'), dataIndex: 'powderId', key: 'powderId', width: 100 },
            {
                title: this.$t(''),
                dataIndex: 'surcharge',
                key: 'surcharge',
                scopedSlots: { customRender: 'surcharge' },
                width: 80,
            },
            {
                title: this.$t(''),
                dataIndex: 'select',
                key: 'select',
                scopedSlots: { customRender: 'select' },
                width: 80,
            },
        ];
    }

    private get colorsData(): any {
        if (this.colors) {
            return this.colors.map((color: any) => {
                return {
                    key: color.id,
                    color: color?.hex,
                    colorName: color?.name,
                    colorProducer: color?.colorProducer,
                    powderId: color?.powderId,
                    surcharge: color?.surcharge,
                };
            });
        }

        return [];
    }

    private selectColor(colorId: string) {
        const selectedColor = this.colors.find((color: any) => color.id === colorId);
        const formattedColor = formatSelectedColorForCustomRequest(selectedColor);

        this.$emit('selectColor', formattedColor);
    }

    private mounted() {
        this.removePaginationTooltips();
    }
}
