import { Model } from '@vuex-orm/core';
import offerNoteAPI from '@/api/OfferNoteAPI';

export default class OfferNote extends Model {
    public static entity = 'offerNote';

    public static fields() {
        return {
            id: this.attr(''),
            offer_id: this.attr(null),
            note: this.attr(''),
        };
    }

    public static async getAll() {
        let offerNotes;

        try {
            offerNotes = await offerNoteAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: offerNotes.data,
        });

        return Promise.resolve(offerNotes);
    }

    public static async getById(offerId: string) {
        let offerNote;

        try {
            offerNote = await offerNoteAPI.getById(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: offerNote.data,
        });

        return Promise.resolve(offerNote);
    }

    public static async updateExisting(payload: any) {
        let offerNote;

        try {
            offerNote = await offerNoteAPI.update(payload);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: offerNote.data,
        });

        return Promise.resolve(offerNote);
    }

    public static async createNew(payload: any) {
        let offerNote;

        try {
            offerNote = await offerNoteAPI.create(payload);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: offerNote.data,
        });

        return Promise.resolve(offerNote);
    }

    public static async deleteOffer(offerId: string) {
        let offerNote;

        try {
            offerNote = await offerNoteAPI.delete(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.deleteOffer(offerId);

        return Promise.resolve(offerNote);
    }

    public note!: string;
}
