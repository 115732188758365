
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { UserRepository } from '@/repositories/UserRepository';
import Project from '@/models/Project';
import ProjectRepository from '@/repositories/ProjectRepository';
import VuePdfApp from 'vue-pdf-app';
import { PdfViewConfig } from '@/enums/global/PdfViewConfig';
// import this to use default icons for buttons
import 'vue-pdf-app/dist/icons/main.css';

@Component({
    name: 'PdfPreviewOfferPopup',
    components: {
        VuePdfApp,
        PdfViewConfig,
    },
})
export default class PdfPreviewOfferPopup extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private isPendingRequest = false;
    private src: string = '';
    private fileName: string = '';
    private errorList: any[] = [];
    private showAllErrors: boolean = false;
    private pdfConfig = PdfViewConfig;

    protected data() {
        const data = this.popupData?.pdfData.file.data;
        const file = new Blob([data], { type: 'application/pdf' });
        const errorList = this.popupData.pdfData.pdf.data.errors;

        return {
            fileName: this.popupData?.pdfName,
            // @ts-ignore
            src: URL.createObjectURL(file),
            errorList,
        };
    }

    private get hasErrors() {
        return this.errorList.length > 0;
    }

    private get isCurrentUserTechnologist() {
        return UserRepository.isCurrentUserTechnologist();
    }

    private async createOffer(sendEmail = false) {
        const project = ProjectRepository.getProjectById(this.$route.params.id);
        const user = UserRepository.getCurrentUser();
        if (project && user && user.id !== project.projectMadeByUser.id) {
            await Project.updateExisting({
                projectId: String(project.id),
                madeByUserId: user?.id,
            });
        }
        this.isPendingRequest = true;
        await this.popupData?.createOrderAction(sendEmail);
        this.isPendingRequest = false;
    }

    private onDownload() {
        const downloadLink = document.createElement('a');
        downloadLink.href = this.src;
        downloadLink.download = this.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }
}
