
import { Component, Vue } from 'vue-property-decorator';
import Translation from '@/models/Translation';
import TranslationRepository from '@/repositories/TranslationRepository';
import { RouteNames } from '@/enums/routes/RouteNames';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import NotAllowedComponent from '../components/views/settings/NotAllowedComponent.vue';

@Component({
    name: 'Translations',
    components: { SettingsTopBar, NotAllowedComponent },
})
export default class Translations extends mixins<UserRightsMixin>(UserRightsMixin) {
    private backRoute: RouteNames = RouteNames.settings;
    private saveAllTranslationsEvent = EventBusEvents.saveAllTranslations;
    private userHasRights: boolean = false;
    private collapsed: boolean = false;

    private get availableTranslationObjects() {
        const translation: Translation | null = TranslationRepository.getTranslation();

        if (translation == null) {
            return [];
        }

        const translationObjects = translation.getTranslatableObjects.filter((object: any) => {
            // Write regex that matches any string that contains the word 'color' or 'powder' in it
            const regex = new RegExp('color|powder', 'i');

            return !regex.test(object.entityName);
        });

        return translationObjects;
    }

    private get availableTranslationColorObjects() {
        const translation: Translation | null = TranslationRepository.getTranslation();

        if (translation == null) {
            return [];
        }

        const translationObjects = translation.getTranslatableObjects.filter((object: any) => {
            // Write regex that matches any string that contains the word 'color' in it
            const regex = new RegExp('color', 'i');

            return regex.test(object.entityName);
        });

        return translationObjects;
    }

    private get isMediumDeviceScreen() {
        const width = this.innerWidth < 768;

        if (!width) {
            this.collapsed = false;
        }

        return width;
    }

    private get innerWidth() {
        return this.$store.getters['generic/innerWidth'];
    }

    private goToTranslationScreen(entityName: string, attribute: string) {
        this.$router.push({
            name: RouteNames.translation,
            params: {
                entityName,
                attribute,
            },
        });
    }

    private async created() {
        try {
            if (this.canSeeTranslationsItem) {
                this.userHasRights = true;
                Translation.getAll();
            }
        } catch (e) {
            return;
        }
    }
}
