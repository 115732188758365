
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppSidebar from '@/components/global/sidebar/AppSidebar.vue';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ProjectViewSidebarPriceCard from '@/components/global/sidebar/ProjectViewSidebarPriceCard.vue';
import ProjectViewSidebarProgressCard from '@/components/global/sidebar/ProjectViewSidebarProgressCard.vue';
import ProjectViewSidebarMessagesCard from '@/components/global/sidebar/ProjectViewSidebarMessagesCard.vue';
import ProjectViewSidebarActivitiesCard from '@/components/global/sidebar/ProjectViewSidebarActivitiesCard.vue';
import ProjectViewSidebarAttachmentsCard from '@/components/global/sidebar/ProjectViewSidebarAttachmentsCard.vue';
import ProjectViewSidebarGeneralInfoCard from '@/components/global/sidebar/ProjectViewSidebarGeneralInfoCard.vue';
import ProjectViewSidebarHistoryCard from '@/components/global/sidebar/ProjectViewSidebarHistoryCard.vue';
import ProjectViewSidebarCustomOrderSwitch from '@/components/global/sidebar/ProjectViewSidebarCustomOrderSwitch.vue';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import Project from '@/models/Project';

@Component({
    name: 'ProjectViewSidebar',
    components: {
        UserRightsMixin,
        AppSidebar,
        SidebarCard,
        ProjectViewSidebarPriceCard,
        ProjectViewSidebarProgressCard,
        ProjectViewSidebarMessagesCard,
        ProjectViewSidebarActivitiesCard,
        ProjectViewSidebarAttachmentsCard,
        ProjectViewSidebarGeneralInfoCard,
        ProjectViewSidebarHistoryCard,
        ProjectViewSidebarCustomOrderSwitch,
    },
})
export default class ProjectViewSidebar extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private basicProjectData!: any;
    @Prop({ required: false }) private basicStateSpecificId!: string;
    @Prop({ required: false }) private isCurrentUserTechnologist!: boolean;
    @Prop({ required: true }) private lockEditing!: boolean;

    private loading = false;

    @Watch('project', {
        immediate: true,
    })
    private onProjectChange() {
        if (!this.project || !this.basicProjectData) {
            this.loading = true;
        }

        if (this.project || this.basicProjectData) {
            this.loading = false;
        }
    }
}
