
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppTable from '@/components/global/app-table/AppTable.vue';
import { clientColumnData, createColumnData } from '@/helpers/TableHelper';
import { FilterParameters } from '@/interfaces/components/FilterParameters';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LocalStorageService } from '@/services/LocalStorageService';
import { ColumnSortedInfo } from '@/interfaces/ColumnSortedInfo';
import { UserRepository } from '@/repositories/UserRepository';
import Client from '@/models/Client';
import { SortOrder } from 'ant-design-vue/types/table/column';
import TableFilters from '@/components/views/home/TableFilters.vue';
import { fetchClientFilterRelatedEntities } from '@/helpers/SearchHelper';
import { Getter } from 'vuex-class';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { getAppropriateFilterOptions } from '@/helpers/NewProjectSearchBarHelper';
import { SearchEntityFilter } from '@/interfaces/SearchEntityFilter';

@Component({
    name: 'ClientsTableWrapper',
    components: {
        TableFilters,
        AppTable,
    },
})
export default class ClientsTableWrapper extends Vue {
    @Getter('tableData/clients')
    private clients!: boolean;

    @Prop({ required: false }) private searchMessage?: string;

    private sortField: string = 'name';
    private sortOrder: SortOrder | undefined = 'descend';
    private sortedInfo: ColumnSortedInfo | null = {
        columnKey: this.sortField,
        order: this.sortOrder,
    };
    private areFiltersVisible = false;
    private isLoading = false;
    private areClientRelatedEntitiesLoaded = false;

    private get user() {
        return UserRepository.getCurrentUser();
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get isCurrentUserGroupRoltek() {
        return this.currentUserGroup && this.currentUserGroup.name === 'Roltek';
    }

    private get sortedClients() {
        return this.clients;
    }

    private getColumnData() {
        const columnOptions = clientColumnData;

        return createColumnData<Client>(columnOptions, this.sortedInfo, 'clients', this.isCurrentUserGroupRoltek);
    }

    private updateSortedInfo(sorter: ColumnSortedInfo) {
        this.sortedInfo = sorter;
        this.sortField = sorter.columnKey;
        this.sortOrder = sorter.order;
    }

    private async onUpdateSorter(params: FilterParameters) {
        this.startLoading();

        this.updateSortedInfo({
            columnKey: params.sortColumnKey,
            order: params.sortOrder,
        });

        EventBus.$emit(EventBusEvents.runEntitySearch, { ...params, shouldOverrideSortParameters: false });
    }

    private async toggleFilters() {
        this.areFiltersVisible = !this.areFiltersVisible;

        if (this.areFiltersVisible && !this.areClientRelatedEntitiesLoaded) {
            this.areClientRelatedEntitiesLoaded = true;
            this.startLoading();
            await fetchClientFilterRelatedEntities();
            this.stopLoading();
        }
    }

    private fireToggleFilters() {
        EventBus.$emit(EventBusEvents.toggleFilters);
    }

    private startLoading() {
        this.isLoading = true;
    }

    private stopLoading() {
        this.isLoading = false;
    }

    private onSearchFinished() {
        this.stopLoading();
    }

    private removeDeletedClientFromTable(clientID: string) {
        // @ts-ignore
        this.clients.splice(
            // @ts-ignore
            this.clients.findIndex((client: Client) => client.id === clientID),
            1
        );
    }

    private created() {
        EventBus.$on(EventBusEvents.updateSortedInfo, this.updateSortedInfo);
        EventBus.$on(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$on(EventBusEvents.searchFinished, this.onSearchFinished);
        EventBus.$on(EventBusEvents.removeDeletedClientFromTable, this.removeDeletedClientFromTable);
    }

    private async mounted() {
        Client.deleteAll();
        this.startLoading();
        await fetchClientFilterRelatedEntities();
        const filterOptions = getAppropriateFilterOptions({
            entityType: 'clients',
            currentUser: this.user,
        }) as SearchEntityFilter[];
        if (LocalStorageService.checkIfLocalStorageParametersShouldBeApplied(this.user, 'clients')) {
            const localStorageParameters = JSON.parse(
                LocalStorageService.get(
                    // @ts-ignore
                    `clients-${this.user.id}`
                ) as string
            );
            localStorageParameters.availableFilters.forEach((filter: SearchEntityFilter) => {
                const selectedFilterOption = filterOptions.find((filterOption: SearchEntityFilter) => {
                    return filterOption.filter === filter.filter;
                });
                // @ts-ignore
                if (selectedFilterOption == null) {
                    return;
                }
                selectedFilterOption.value = filter.value;
            });

            this.updateSortedInfo({
                columnKey: localStorageParameters.sortField,
                order: localStorageParameters.sortOrder,
            });

            const searchParameters = {
                isSearchActive: localStorageParameters.isSearchActive ?? true,
                entityType: localStorageParameters.entityType ?? 'clients',
                availableFilters: filterOptions,
                page: localStorageParameters?.page ?? 1,
                searchQuery: localStorageParameters?.searchQuery ?? '',
            };
            EventBus.$emit(EventBusEvents.runEntitySearchWithParameters, searchParameters);
            return;
        }

        EventBus.$emit(EventBusEvents.runEntitySearch);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateSortedInfo, this.updateSortedInfo);
        EventBus.$off(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$off(EventBusEvents.searchFinished, this.onSearchFinished);
        EventBus.$off(EventBusEvents.removeDeletedClientFromTable, this.removeDeletedClientFromTable);
    }
}
